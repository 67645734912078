<template>
    <form :action="`${apiUrl}cards/preview/?pr=1&d=0&comp=${compId}&t=${selectedTemplateSlug}`"
          method="post"
          target="cardPreview"
          ref="cardPreview">
        <input type="hidden" name="preview_data" value="1"/>
        <h3 class="text-center">
            {{$ml.get('CUSTOMIZE_CARD')}}
        </h3>

        <div class="row">
        <template v-for="(inputField, index) in fieldSet">
            <div :class="inputField.col" v-bind:key="index">
            <dropzone-field v-if="inputField.fieldType == 'DropzoneField'" v-bind:key="index"
                            :name="inputField.field"
                            :id="inputField.field"
                            :accept="`image/*`"
                            v-model="images[inputField.fieldType]">
                <template v-if="images[inputField.field]._url">
                <img :src="images[inputField.field]._url"
                     v-if="images[inputField.field]._url !== undefined">
                </template>
                <template v-else>
                    <div v-html="$ml.get(inputField.label)"></div>
                </template>



            </dropzone-field>
            <slider-field
                    v-else-if="inputField.fieldType == 'SliderField'" v-bind:key="index"
                    :name="inputField.field"
                    :id="inputField.field"
                    :value="form[inputField.field]"
                    v-model="form[inputField.field]">
                {{$ml.get(inputField.label)}}&nbsp;(%)
            </slider-field>
            <color-field
                    v-else-if="inputField.fieldType == 'ColorField'" v-bind:key="index"
                    :name="inputField.field"
                    :id="inputField.field"
                    :value="form[inputField.field]"
                    v-model="form[inputField.field]">
                {{$ml.get(inputField.label)}}
            </color-field>
            <input-field
                    v-else-if="inputField.fieldType == 'InputField'" v-bind:key="index"
                    :name="inputField.field"
                    :id="inputField.field"
                    :value="form[inputField.field]"
                    v-model="form[inputField.field]">
                {{$ml.get(inputField.label)}}
            </input-field>
            <select-field
                    v-else-if="inputField.fieldType == 'SelectField'" v-bind:key="index"
                    :name="inputField.field"
                    :id="inputField.field"
                    :value="form[inputField.field]"
                    v-model="form[inputField.field]">
                          :options="inputField.options">
                {{$ml.get(inputField.label)}}
            </select-field>
            <hr v-else-if="inputField.field == 'Separator'">
            </div>
        </template>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              icon="copy"
                              type="button"
                              :disabled="loading"
                              :loading="loading"
                              block
                              @click="submitForm">{{$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardCardDesignForm",
        computed: {
            id() {
                return this.$store.state.dashboardModalComponent.data.id;
            },
            cardConfig() {
                return this.$store.state.cardConfig;
            },
            useDefaults() {
                return this.$store.state.dashboardModalComponent.data.useDef;
            }
        },
        data() {
            let cardConfigData = this.$store.state.cardConfig;
            let selectedTemplate = this.$store.state.selectedTemplate;
            let fieldSet = selectedTemplate.attributes.availableParams;
            let defaults = {
                fieldSet: {},
                form: {},
                images: {
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },
                imgFiles: {
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },
                loading: false,
                timeout: null,
                apiUrl: process.env.VUE_APP_BASE_API_URL,
                compId: this.$store.state.cardCompany.id,
                selectedTemplateSlug: this.$store.state.selectedTemplate.attributes.nice_name,
                selectedTemplateId: this.$store.state.selectedTemplate.id
            };

            //console.log("cardConfig data: ",cardConfigData);
            //console.log("Template data: ",selectedTemplate);
            //console.log("Field set: ", fieldSet);
            let formFields = {};
            let self = this;
            fieldSet.forEach(function(oneField){
                switch (oneField.field) {
                    case "imgLogo":
                        defaults.images.imgLogo = cardConfigData.attributes.imgLogo ? cardConfigData.attributes.imgLogo : selectedTemplate.attributes.imgLogo;
                        break;

                    case "imgBgTop":
                        defaults.images.imgBgTop = cardConfigData.attributes.imgBgTop ? cardConfigData.attributes.imgBgTop :  {_url: null};
                        break;

                    case "imgBgFull":
                        defaults.images.imgBgFull = cardConfigData.attributes.imgBgFull ? cardConfigData.attributes.imgBgFull : {_url: null};
                        break;

                    case "Separator":

                        break;

                    default:
                        if(self.$store.state.dashboardModalComponent.data.useDef){
                            formFields[oneField.field] =  oneField.defaultValue;
                        }else{
                            formFields[oneField.field] =  cardConfigData.attributes[oneField.field] ? cardConfigData.attributes[oneField.field]: oneField.defaultValue;
                        }

                }
            });
            //console.log("Use default settings: ", self.$store.state.dashboardModalComponent.data.useDef);
            defaults.fieldSet = fieldSet;
            defaults.form = formFields;
            //console.log("Form on-load data:", defaults);
            return defaults;

        },
        mounted() {
            this.refreshPreview(true);
        },
        watch: {
            form: {
                handler() {
                    this.refreshPreview();
                },
                deep: true,
            },
            images: {
                handler(data) {
                    this.imgFiles[data.DropzoneField.field] = data.DropzoneField;
                    this.refreshPreview();
                },
                deep: true,
            }
        },
        methods: {
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveCardConfig', {
                        id: this.id,
                        form: this.form,
                        imgFiles: this.imgFiles,
                        templateId: this.selectedTemplateId,
                        designVer: self.$store.state.cardConfig.attributes.designVer
                    }).then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                    });
                }
            },
            refreshPreview(fast) {
                clearTimeout(this.timeout);
                if(fast){
                    this.$refs.cardPreview.submit();
                }else{
                    this.timeout = setTimeout(() => {
                        this.$refs.cardPreview.submit();
                    }, 500);
                }

            }
        }
    }
</script>

<style scoped>
 .col-6 {
     display: flex;
     align-items: center;
 }
 hr {
     border: 0;
     border-top: 10px solid var(--color-grey);
     padding-bottom: 15px;
     margin: 0 -15px;
 }
</style>
