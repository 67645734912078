<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('DELETE_CLIENT')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('CLIENT_COMPANY_NAMES')}}
        </div>
        <ul>
            <li class="small"
                v-for="clientCompany in agencyClients"
                :key="clientCompany.id">
                {{clientCompany.attributes.name}}
            </li>
        </ul>
        <div class="pt-4">
            <checkbox-field color="secondary"
                            name="confirm"
                            id="confirm"
                            :value="confirm"
                            v-model="confirm">
                {{$ml.get('delete_client_confirm_checkbox')}}
            </checkbox-field>
            <p>{{$ml.get('beware_data_deletion')}}</p>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="error"
                              icon="trash-alt"
                              :loading="loading"
                              :disabled="loading || !confirm"
                              block
                              @click="deleteClients">{{$ml.get('DELETE')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardClientsDelete",
        computed: {
            agencyClients() {
                return this.$store.getters.selectedModalData('agencyClients');
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            deleteClients() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('deleteClient').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_delete'),
                        });
                        this.$store.commit('setCardCompany', this.$store.state.userCompany);
                        self.$store.dispatch('getAgencyClients');
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
</style>
