<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_OFFICE_ADDRESS'):$ml.get('EDIT_OFFICE_ADDRESS')}}
        </h3>
        <div>
            <input-field :id="`office-label`"
                         :name="'office-label'"
                         :value="form.officeLabel"
                         v-model="form.officeLabel">{{$ml.get('ADDRESS_LABEL')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-phone`"
                         :name="'office-phone'"
                         :value="form.officePhone"
                         v-model="form.officePhone">{{$ml.get('OFFICE_PHONE')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-website`"
                         :name="'office-website'"
                         :value="form.website"
                         v-model="form.website">{{$ml.get('WEBSITE')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-address`"
                         :name="'office-address'"
                         :value="form.street"
                         v-model="form.street">{{$ml.get('ADDRESS')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-zip`"
                         :name="'office-zip'"
                         :value="form.zip"
                         v-model="form.zip">{{$ml.get('ZIP')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-city`"
                         :name="'office-city'"
                         :value="form.city"
                         v-model="form.city">{{$ml.get('CITY')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-state`"
                         :name="'office-state'"
                         :value="form.state"
                         v-model="form.state">{{$ml.get('STATE')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-country`"
                         :name="'office-country'"
                         :value="form.country"
                         v-model="form.country">{{$ml.get('COUNTRY')}}
            </input-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="loading"
                              :loading="loading"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardCompanyCoordinatesForm",
        computed: {
            id() {
                return this.$store.state.dashboardModalComponent.data.id;
            },
            officeAddress() {
                return this.$store.getters.selectedModalData('officeAddresses');
            }
        },
        data() {
            return {
                form: {
                    officeLabel: '',
                    officePhone: '',
                    street: '',
                    zip: '',
                    city: '',
                    country: '',
                    state: '',
                    website: ''
                },
                loading: false,
            }
        },
        mounted() {
            if (this.id !== null) {
                this.form = {
                    officeLabel: this.officeAddress.attributes.officeLabel,
                    officePhone: this.officeAddress.attributes.officePhone,
                    street: this.officeAddress.attributes.street,
                    zip: this.officeAddress.attributes.zip,
                    city: this.officeAddress.attributes.city,
                    country: this.officeAddress.attributes.country,
                    state: this.officeAddress.attributes.state,
                    website: this.officeAddress.attributes.website
                };
            }
        },
        methods: {
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveOfficeAddress', {id: this.id, ...this.form}).then(() => {
                        this.$store.dispatch('getOfficeAddresses').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>
</style>
