<template>
    <form class="auth-form"
          @submit.prevent="login">
        <div class="row">
            <div class="col text-center login-logo-row">
                <img src="@/assets/ecobcard-logo-white.svg" alt="logo">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-9">
                <input-field name="username"
                             id="username"
                             :value="formData.username"
                             v-model="formData.username">{{$ml.get('EMAIL')}}
                </input-field>
            </div>
        </div>
        <div class="row justify-content-center mb-3">
            <div class="col-9">
                <input-field name="password"
                             id="password"
                             type="password"
                             :value="formData.password"
                             v-model="formData.password">{{$ml.get('PASSWORD')}}
                </input-field>
            </div>
        </div>
        <div class="row justify-content-center mb-3">
            <div class="col-4">
                <button-field color="secondary"
                              type="submit"
                              block
                              :disabled="loading"
                              icon="sign-in-alt"
                              :loading="loading">
                    {{$ml.get('LOGIN')}}
                </button-field>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col text-center small">
                <router-link :to="{name: 'forgottenPassword'}">FORGOTTEN PASSWORD?</router-link>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "AuthenticateLogin",
        data() {
            return {
                loading: false,
                formData: {
                    username: '',
                    password: ''
                }
            }
        },
        methods: {
            login() {
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('login', this.formData).then((userdata) => {

                        if(userdata){
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_login_success')+', '+userdata.attributes.firstName+'!'
                            });
                        }
                        if(userdata.attributes.isAdmin){
                            this.$router.push({name: 'adminCards'});
                        }else {
                            this.$router.push({name: 'employeeCards'});
                        }

                    }).catch(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: this.$ml.get('notification_login_error')
                        });
                    })
                }
            }
        }
    }
</script>

<style scoped>
</style>
