import axios from 'axios'

export default () => {
    return axios.create({
        baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
        withCredentials: false,
        headers: {
            'Accept': '*/*',
            'Content-Type': 'multipart/form-data',
            'Accept-Language' : 'en'
        }
    })
}
