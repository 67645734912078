import Vue from 'vue'
import Vuex from 'vuex'
import {actions} from '../services/actions'

import * as deviceDetector from 'mobile-device-detect';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        deviceData: deviceDetector,
        isAuthenticated: false,
        globalLoader: false,
        user: null,
        userCompany: null,
        cardCompany: null,
        cardConfig: {
            attributes : {
                template: {
                    attributes: {
                        nice_name : ""
                    }
                }
            }
        },
        cardTemplates: [],
        selectedTemplate: null,
        dashboardModalComponent: {
            state: false,
            name: 'DashboardEmployeeCardsForm',
            data: null
        },
        employeeCards: [],
        employeeCardCount: 0,
        employeeCardPage: 1,
        employeeCardsPP: 25,
        agencyClients: [],
        officeAddresses: []
    },
    getters: {
        selectedModalData: state => field => {
            if (
                state.dashboardModalComponent.data !== null &&
                state.dashboardModalComponent.data.id !== null &&
                state.dashboardModalComponent.data.ids === undefined
            ) {
                return state[field].find(obj => {
                    return obj.id === state.dashboardModalComponent.data.id;
                });
            } else if (
                state.dashboardModalComponent.data !== null &&
                state.dashboardModalComponent.data.ids !== null &&
                state.dashboardModalComponent.data.id !== null
                ) {
                return state[field].filter(obj => {
                    return state.dashboardModalComponent.data.ids.includes(obj.id);
                });
            } else {
                return null;
            }
        },
    },
    mutations: {
        resetState (state) {
            Object.assign(state, {
                deviceData: deviceDetector,
                isAuthenticated: false,
                globalLoader: false,
                user: null,
                userCompany: null,
                cardCompany: null,
                cardConfig: undefined,
                cardTemplates: [],
                selectedTemplate: null,
                dashboardModalComponent: {
                    state: false,
                    name: 'DashboardEmployeeCardsForm',
                    data: null
                },
                employeeCards: [],
                employeeCardCount: 0,
                employeeCardPage: 1,
                employeeCardsPP: 25,
                agencyClients: [],
                officeAddresses: []
            });
        },
        setDeviceData(state, data) {
            state.deviceData = data;
        },
        setIsAuthenticated(state, data) {
            state.isAuthenticated = data;
        },
        setGlobalLoader(state, data) {
            state.globalLoader = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setUserCompany(state, data) {
            state.userCompany = null;
            state.userCompany = data;
            if(data.attributes.impersonating){
                state.cardCompany = data.attributes.impersonating;
            }else{
                state.cardCompany = data;
            }
        },
        setCardCompany(state, data) {
            state.cardCompany = data;
        },
        setCardConfig(state, data) {
            state.cardConfig = data;
        },
        setCardTemplates(state, data) {
            state.cardTemplates = data;
        },
        setActiveTemplate(state, data){
            state.selectedTemplate = data;
        },
        toggleDashboardModal(state, data = null) {
            if (data !== null && !state.dashboardModalComponent.state) {
                state.dashboardModalComponent.name = data.name;
                state.dashboardModalComponent.data = data.data;
            }
            state.dashboardModalComponent.state = !state.dashboardModalComponent.state;
        },
        closeRightModal(state) {
            state.dashboardModalComponent.state = false;
        },
        setEmployeeCards(state, employees) {
            state.employeeCards = employees;
        },
        setEmployeeCardCount(state, count){
            state.employeeCardCount = count;
        },
        setEmployeeCardPage(state, pagenum){
            state.employeeCardPage = pagenum;
        },
        setEmployeeCardsPP(state, cardsperpage){
            state.employeeCardsPP = cardsperpage;
        },
        setAgencyClients(state, employees) {
            state.agencyClients = employees;
        },
        setOfficeAddresses(state, addresses) {
            state.officeAddresses = addresses;
        }

    },
    actions,
    modules: {},
    methods: {

    }

});
