import Vue from 'vue'
import VueRouter from 'vue-router'

import Authenticate from '../components/authenticate/Authenticate'
import AuthenticateLogin from '../components/authenticate/AuthenticateLogin'
import AuthenticateForgotten from '../components/authenticate/AuthenticateForgotten'
import AuthenticateResetPassword from '../components/authenticate/AuthenticateResetPassword'

import StyleComponents from '../components/global/StyleComponents'

import DashboardLayout from '../components/layout/Dashboard'
import DashboardEmployeeCards from '../components/pages/EmployeeCards'
import DashboardCardDesign from '../components/pages/CardDesign'
import DashboardCompanyCoordinates from '../components/pages/CompanyCoordinates'
import DashboardBilling from '../components/pages/Billing'
import DashboardClients from '../components/pages/Clients'
import DashboardProfile from '../components/pages/DashboardProfile'

import AdminEmployeeCards from '../components/pages/AdminEmployeeCards'
import AdminEstimates from '../components/pages/AdminEstimates'

import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    // {
    //     path: '/',
    //     component: Dashboard,
        // beforeEnter: (to, from, next) => {
        //     store.dispatch('currentUser').then(() => {
        //         if (store.state.isAuthenticated) {
        //             next();
        //         } else {
        //             next('/auth')
        //         }
        //     }).catch(() => {
        //         if (store.state.isAuthenticated) {
        //             next();
        //         } else {
        //             next('/auth')
        //         }
        //     });
        // },
    //     children: [
    //         {
    //             path: '',
    //             name: 'dashboard',
    //             component: DashboardDesign
    //         }
    //     ]
    // },
    {
        path: '/auth',
        component: Authenticate,
        beforeEnter: (to, from, next) => {
            store.dispatch('currentUser').then(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/');
                }
            }).catch(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/')
                }
            });
        },
        children: [
            {
                path: '',
                name: 'auth',
                component: AuthenticateLogin
            },
            {
                path: 'forgotten-password',
                name: 'forgottenPassword',
                component: AuthenticateForgotten
            },
            {
                path: 'password-reset',
                name: 'passwordReset',
                component: AuthenticateResetPassword
            },
        ]
    },
    {
        path: '/style-components',
        name: 'StyleComponents',
        component: StyleComponents
    },
    {
        path: '/',
        beforeEnter: (to, from, next) => {
            store.dispatch('currentUser').then(() => {
                if (store.state.isAuthenticated) {
                    next();
                } else {
                    next('/auth')
                }
            }).catch(() => {
                if (store.state.isAuthenticated) {
                    next();
                } else {
                    next('/auth')
                }
            });
        },
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'employeeCards',
                component: DashboardEmployeeCards,
                beforeEnter: (to, from, next) => {
                    store.dispatch('currentUser').then(() => {
                        if (store.state.user.attributes.isAdmin) {
                            next({name: 'adminCards'});
                        } else {
                            next();
                        }
                    })
                }
            },
            {
                path: 'admin-cards',
                name: 'adminCards',
                component: AdminEmployeeCards,
            },
            {
                path: 'billing-estimates',
                name: 'billingEstimate',
                component: AdminEstimates,
            },
            {
                path: 'card-design',
                name: 'cardDesign',
                component: DashboardCardDesign
            },
            {
                path: 'office-addresses',
                name: 'companyCoordinates',
                component: DashboardCompanyCoordinates
            },
            {
                path: 'clients',
                name: 'clients',
                component: DashboardClients
            },
            {
                path: 'billing',
                name: 'billing',
                component: DashboardBilling
            },
            {
                path: 'profile',
                name: 'profile',
                component: DashboardProfile
            }
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
