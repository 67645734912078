<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="downloadData()"
                                  icon="file-export"
                                  color="secondary"
                                  :disabled="exportLoading"
                                  :loading="exportLoading">{{$ml.get('EXPORT_TO_CSV')}}
                    </button-field>
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <!--<div class="table-top-search">-->
                    <!--{{$ml.get('Search')}}-->
                <!--</div>-->
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                            <tr>
                                <th>{{$ml.get('FIRST_NAME')}}</th>
                                <th>{{$ml.get('LAST_NAME')}}</th>
                                <th>{{$ml.get('EMAIL')}}</th>
                                <th>{{$ml.get('POSITION')}}</th>
                                <th>{{$ml.get('CREATED_ON')}}</th>
                                <th>{{$ml.get('UPDATED_ON')}}</th>
                                <th>{{$ml.get('SENT_ON')}}</th>
                                <th>{{$ml.get('VIEW_COUNT')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in tableRows" v-bind:key="index">
                            <td>
                                <div class="ellipsis">{{item.attributes.firstName}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.lastName}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.email}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.position}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{niceDate(item.attributes.createdAt)}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{niceDate(item.attributes.updatedAt)}}</div>
                            </td>
                            <td>
                                <div v-if="item.attributes.sentAt" class="ellipsis">{{niceDate(item.attributes.sentAt)}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{item.attributes.viewCount}}</div>
                            </td>
                            <td>
                                <div class="table-action-btn-gorup">
                                    <button-field :disabled="sharing"
                                                  circle
                                                  outline
                                                  @click="previewCards([item.id], [item.attributes.cardToken])"
                                                  icon="eye"
                                                  class="table-action-btn"
                                                  v-tooltip="'Preview card'"
                                                  color="dark"></button-field>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">

                </div>
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "AdminEmployeeCards",
        computed: {
            tableRows() {
                return this.$store.state.employeeCards;
            },
            resultsPerPage() {
                return this.$store.state.employeeCardsPP;
            },
            totalResults() {
                return this.$store.state.employeeCardCount;
            },
            resultsFrom() {
                if(this.$store.state.employeeCardCount == 0) {
                    return 0;
                }
                else {
                    return ((this.curPageValue-1)*this.perPageValue)+1;
                }

            },
            resultsTo() {
                let pageMaxResults = this.curPageValue*this.perPageValue;

                if(pageMaxResults > this.$store.state.employeeCardCount){
                    return this.$store.state.employeeCardCount;
                }else{
                    return pageMaxResults;
                }

            },
            getCurPageValue() {
                return this.$store.state.employeeCardPage;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                        {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            maxPages() {
              return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                perPageOptions: [
                    {
                        value: 5,
                        text: 5
                    },
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                curPageValue: 1,
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                exportLoading: false,
            }
        },
        mounted() {
            let self = this;
            this.$store.dispatch('getCompanyAddress').then(() => {
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            downloadData(){
                const companyName = this.$store.state.cardCompany.attributes.name;
                this.exportLoading = true;
                this.$store.dispatch('getAllEmployeeCards').then((results) => {
                    const csvString = [
                        [
                            "First Name",
                            "Last Name",
                            "E-MAIL",
                            "Position",
                            "Created On",
                            "Updated On",
                            "Sent On",
                            "View Count",
                        ],
                        ...results.map(item => [
                            item.attributes.firstName,
                            item.attributes.lastName,
                            item.attributes.email,
                            item.attributes.position,
                            item.attributes.createdAt ? this.exportDate(item.attributes.createdAt) : '',
                            item.attributes.updatedAt ? this.exportDate(item.attributes.updatedAt) : '',
                            item.attributes.sentAt ? this.exportDate(item.attributes.sentAt) : '',
                            item.attributes.viewCount,
                        ])
                    ]
                        .map(e => e.join(","))
                        .join("\n");
                    const blob = new Blob([csvString], { type: 'text/csv' }) //  Structure 1 A blob Object to process data
                    const fileName = 'QFECard_export-'+companyName+'-'+moment().format('Y-MM-DD')+'.csv' //  Export file name
                    const elink = document.createElement('a') //  Create a Label
                    elink.download = fileName // a Tag Add Attribute
                    elink.style.display = 'none'
                    elink.href = URL.createObjectURL(blob)
                    document.body.appendChild(elink)
                    elink.click() //  Perform a download
                    URL.revokeObjectURL(elink.href) //  Release URL  Object
                    document.body.removeChild(elink) //  Release label
                    this.exportLoading = false;

                });
            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            exportDate(date){
                return moment(date).format('Y-MM-DD');
            },
            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }
            },
            updateResultsPP(){
                let self = this;
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            deleteEmployee(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsDelete', data: {ids: [id]}})
            },
            previewCards(ids,token) {
                window.open(process.env.VUE_APP_BASE_API_URL+"cards/?u="+ids+"&s="+token, '_blank');
            },
        },
        watch : {
            perPageValue(newVal){
                this.$store.commit('setEmployeeCardsPP', newVal);
                this.$store.dispatch('setEmployeeCardPage', 1);
                this.curPageValue = 1;
                this.updateResultsPP();
            },
            curPageValue(newPageVal){
                this.$store.commit('setEmployeeCardPage', newPageVal);
                this.updateResultsPP();
            },
        }
    }
</script>

<style scoped>
    .per-page-select {
        width: 110px;
    }
</style>
