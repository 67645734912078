import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import {en} from './en'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'en',
    save: process.env.NODE_ENV === 'production',
    languages: [
        new MLanguage('en').create(en),
    ]
})
