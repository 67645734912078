export const CompanyService = {
    getCompanyAddress(context) {
        let company = context.state.user.get('company');
        return new Promise((resolve, reject) => {
            company.fetchWithInclude('impersonating').then(response => {
                context.commit('setUserCompany', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveCompany(context, data) {
        return new Promise((resolve, reject) => {
            for (const key in data) {
                context.state.userCompany.set(key, data[key]);
            }
            context.state.userCompany.save().then(response => {
                context.commit('setUserCompany', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
