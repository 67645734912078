<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{$ml.get('EDIT_INFORMATION')}}
        </h3>
        <div>
            <input-field name="first-name"
                         id="first-name"
                         :value="form.firstName"
                         v-model="form.firstName">{{$ml.get('FIRST_NAME')}}
            </input-field>
        </div>
        <div>
            <input-field name="last-name"
                         id="last-name"
                         :value="form.lastName"
                         v-model="form.lastName">{{$ml.get('LAST_NAME')}}
            </input-field>
        </div>
        <div>
            <input-field name="email"
                         id="email"
                         type="email"
                         :value="form.email"
                         v-model="form.email">{{$ml.get('EMAIL')}}
            </input-field>
        </div>
        <div>
            <dropzone-field name="avatar"
                            id="avatar"
                            :accept="`image/*`"
                            v-model="avatar">
                <img :src="user.attributes.avatar._url"
                     v-if="user.attributes.avatar !== undefined">
                <template v-else>
                    <div>
                        {{$ml.get('DROP_AVATAR_HERE')}}
                    </div>
                </template>
            </dropzone-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              icon="save"
                              type="submit"
                              block
                              :disabled="loading"
                              :loading="loading">
                    {{$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardProfileForm",
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        data() {
            return {
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                },
                avatar: undefined,
                hasError: false,
                loading: false
            }
        },
        mounted() {
            this.form = {
                firstName: this.user.attributes.firstName,
                lastName: this.user.attributes.lastName,
                email: this.user.attributes.email
            };
        },
        methods: {
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('changeInformation', {form: this.form, avatar: this.avatar}).then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
