<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div class="billing">
                <div class="invoices">
                    <div class="table-top text-center">
                        {{$ml.get('INVOICES')}}
                    </div>
                    <div class="table-list-wrapper">
                        <table class="table-list">
                            <thead class="font-bebas-neue">
                            <tr>
                                <th>{{$ml.get('INVOICE_N')}}</th>
                                <th>{{$ml.get('ISSUED_ON')}}</th>
                                <th>{{$ml.get('FILENAME')}}</th>
                                <th>{{$ml.get('AMOUNT')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="invoice in invoices" :key="invoice.id">
                                <td>
                                    <div>{{invoice.attributes.number}}</div>
                                </td>
                                <td>
                                    <div>{{invoice.attributes.issuedOn | dateFormat}}</div>
                                </td>
                                <td>
                                    <div>
                                        <a :href="invoice.attributes.attachment._url"
                                           :download="invoice.attributes.attachment._name"
                                           target="_blank">
                                            {{invoice.attributes.attachment._name}}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div>{{invoice.attributes.amount}}&nbsp;{{invoice.attributes.currency}}</div>
                                </td>
                                <td>
                                    <div class="table-action-btn-gorup text-center">
                                        <a :href="invoice.attributes.attachment._url"
                                           :download="invoice.attributes.attachment._name"
                                           target="_blank"
                                           class="table-action-btn">
                                            <font-awesome-icon icon="download"/>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-bottom text-center">
                        <div class="table-pagination ">
                            <ul class="pagination">
                                <li><a href="#">« {{$ml.get('Prev')}}</a></li>
                                <li><a href="#">1</a></li>
                                <li><a class="active" href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">18</a></li>
                                <li><a href="#">{{$ml.get('Next')}} »</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="company-billing-info" v-if="company!==null">
                    <div class="table-top text-center justify-content-center">
                        {{$ml.get('COMPANY_BILLING_INFORMATION')}}
                    </div>
                    <div class="company-data">
                        <div><label class="font-bebas-neue">{{$ml.get('COMPANY_NAME')}}</label><span>{{company.attributes.name}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('VAT_NUMBER')}}</label><span>{{company.attributes.vatNumber}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('BILLING_ADDRESS')}}</label><span>{{company.attributes.billingAddress}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('RESPONSIBLE')}}</label><span>{{company.attributes.responsible}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('EMAIL')}}</label><span>{{company.attributes.billingEmail}}</span>
                        </div>
                        <div><label
                                class="font-bebas-neue">{{$ml.get('TELEPHONE')}}</label><span>{{company.attributes.telephone}}</span>
                        </div>
                    </div>

                    <div class="table-bottom table-action-btn-gorup text-center">
                        <button-field @click="toggleModal"
                                      icon="edit"
                                      color="primary">{{$ml.get('EDIT_INFORMATION')}}
                        </button-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardBilling",
        computed: {
            company() {
                return this.$store.state.userCompany;
            },
        },
        data() {
            return {
                invoices: []
            }
        },
        mounted() {
            this.getCompanyAddress();
        },
        methods: {
            getCompanyAddress() {
                let self = this;
                this.$store.dispatch('getCompanyAddress').then(() => {
                    this.$store.dispatch('getInvoices').then(response => {
                        this.invoices = response;
                    })
                }).catch(function(e){
                    self.$checkTokenExp(e)
                });
            },
            toggleModal() {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardBillingForm', data: null})
            }
        }
    }
</script>

<style scoped>
    .table-action-btn {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: var(--color-dark);
        border: 1px solid var(--color-dark);
        border-radius: 50%;
        text-decoration: none;
        /* transition: all 0.25s ease-in-out; */
        background: transparent;
        font-size: 10px;
    }

    .table-action-btn:hover,
    .table-action-btn:focus,
    .table-action-btn:active {
        color: var(--color-white);
        background: var(--color-dark);
    }
    .eco-content-scroll{
        padding: 10px;
    }
    .eco-content-space {
        background: #FFFFFF;
        padding: 25px;
    }
</style>
