<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('SEND_EMPLOYEE_CARDS_TITLE')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('EMPLOYEE_RECIPIENTS')}}
        </div>
        <ul>
            <li class="small"
                v-for="employee in selectedEmployees"
                :key="employee.id">
                {{employee.attributes.firstName}}&nbsp;{{employee.attributes.lastName}}
            </li>
        </ul>

        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <div class="pt-4 pb-4">
                    <checkbox-field color="secondary"
                                    name="confirm"
                                    id="confirm"
                                    :value="confirm"
                                    v-model="confirm">
                        {{$ml.get('SHARE_CARDS_CHECKBOX')}}
                    </checkbox-field>
                </div>
                <button-field color="secondary"
                              icon="share-square"
                              :loading="loading"
                              :disabled="loading"
                              block
                              @click="sendCards(selectedEmployeeIds)">{{$ml.get('SEND_CARDS')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmployeeCards_Share",
        computed: {
            selectedEmployees() {
                return this.$store.getters.selectedModalData('employeeCards');
            },
            selectedEmployeeIds() {
                let employeeObjects = this.$store.getters.selectedModalData('employeeCards');
                let EmployeeIds = employeeObjects.map(obj => {
                    return obj.id;
                });
                return EmployeeIds;
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            sendCards(ids) {
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('shareCards', {ids: ids, resetLink: this.confirm}).then(() => {
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_share_card'),
                        });
                        this.loading = false;
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    })
                }
            },
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
</style>
