<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('DELETE_EMPLOYEE_CARD')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('EMPLOYEE_NAMES')}}
        </div>
        <ul>
            <li class="small"
                v-for="employee in selectedEmployees"
                :key="employee.id">
                {{employee.attributes.firstName}}&nbsp;{{employee.attributes.lastName}}
            </li>
        </ul>
        <div class="pt-4">
            <checkbox-field color="secondary"
                            name="confirm"
                            id="confirm"
                            :value="confirm"
                            v-model="confirm">
                {{$ml.get('delete_employee_confirm_checkbox')}}
            </checkbox-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="error"
                              icon="trash-alt"
                              :loading="loading"
                              :disabled="loading || !confirm"
                              block
                              @click="deleteCards">{{$ml.get('DELETE')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardEmployeeCardsDelete",
        computed: {
            selectedEmployees() {
                return this.$store.getters.selectedModalData('employeeCards');
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            deleteCards() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('deleteEmployeeCards').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_delete'),
                        });
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    });
                }
            }
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
</style>
