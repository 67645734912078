import Parse from 'parse'

export const InvoiceService = {
    getInvoices(context) {
        let invoice = Parse.Object.extend('Invoice');
        let query = new Parse.Query(invoice);
        query.include(context.state.userCompany);
        query.equalTo('company', context.state.userCompany);
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }
};
