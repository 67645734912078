<template>
    <form @submit.prevent="submitForm"
          ref="importCsvFromRef">
        <h3 class="text-center">
            {{$ml.get('IMPORT_FROM_CSV')}}
        </h3>
        <div class="mb-4">
            <dropzone-field name="cards_csv"
                            id="cards_csv"
                            accept=".csv"
                            v-model="importCsv">
                <div>{{$ml.get('IMPORT_FROM_CSV_INPUT_LABEL')}}</div>
            </dropzone-field>
        </div>
        <div>
            <checkbox-field name="overwrite"
                            id="overwrite"
                            :value="overwrite"
                            v-model="overwrite"
                            color="secondary">
                {{$ml.get('IMPORT_FROM_CSV_CHECKBOX_LABEL')}}
            </checkbox-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <input type="hidden" name="action" value="uploadCSV">
                <button-field color="secondary"
                              icon="upload"
                              type="submit"
                              :disabled="disabledSubmit"
                              :loading="loading"
                              block>{{$ml.get('UPLOAD')}}
                </button-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="small" v-html="$ml.get('IMPORT_FROM_CSV_DETAILS')"></p>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <a href="/resources/card_import_template.csv"
                   class="color-secondary"
                   download>card_import_template.csv</a>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardEmployeeCardsImport",
        computed: {
            user() {
                return this.$store.state.user
            },
            disabledSubmit() {
                return (this.importCsv === undefined || this.loading)
            }
        },
        data() {
            return {
                overwrite: false,
                importCsv: undefined,
                loading: false
            }
        },
        methods: {
            submitForm() {
                let self = this;
                if (!this.disabledSubmit) {
                    this.loading = true;
                    let formData = new FormData(this.$refs.importCsvFromRef);
                    formData.append('data', JSON.stringify({
                        sender_id: this.$store.state.user.id,
                        company_id: this.$store.state.cardCompany.id,
                        overwrite: this.overwrite
                    }));
                    this.$store.dispatch('importEmployees', formData).then(() => {
                        this.$store.dispatch('getEmployeeCards').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_import'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        this.loading = false;
                        self.$checkTokenExp(e);
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
