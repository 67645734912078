<template>
    <form class="auth-form"
          @submit.prevent="submitForm">
        <div class="row">
            <div class="col text-center login-logo-row">
                <img src="@/assets/ecobcard-logo-white.svg" alt="logo">
            </div>
        </div>
        <div v-if="!success">
            <div class="row justify-content-center">
                <div class="col-9">
                    <input-field name="password"
                                 :color="error.length?'error':''"
                                 id="password"
                                 type="password"
                                 :value="form.password"
                                 v-model="form.password">{{$ml.get('PASSWORD')}}
                    </input-field>
                </div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-9">
                    <input-field name="password-confirm"
                                 id="password-confirm"
                                 type="password"
                                 :error="error"
                                 :value="form.passwordConfirm"
                                 v-model="form.passwordConfirm">{{$ml.get('PASSWORD_CONFIRM')}}
                    </input-field>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-4">
                    <button-field color="secondary"
                                  type="submit"
                                  block
                                  :disabled="disableSubmit"
                                  :loading="loading">
                        {{$ml.get('RESET_PASSWORD')}}
                    </button-field>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row align-items-center margin-fix">
                <div class="col text-center">
                    <h3 class="color-secondary">{{$ml.get('Success')}}</h3>
                    <p v-html="$ml.get('passwordForgottenSuccess')"></p>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col text-center small">
                    <router-link :to="{name: 'auth'}">{{$ml.get('LOGIN')}}</router-link>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "AuthenticateResetPassword",
        computed: {
            token() {
                return this.$route.query.s;
            },
            id() {
                return this.$route.query.u;
            },
            disableSubmit() {
                return (this.loading || this.form.password.length === 0 || this.form.passwordConfirm.length === 0)
            }
        },
        data() {
            return {
                form: {
                    password: '',
                    passwordConfirm: ''
                },
                loading: false,
                success: false,
                error: ''
            }
        },
        methods: {
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.error = '';
                    if (this.form.password !== this.form.passwordConfirm) {
                        this.error = this.$ml.get('change_password_error');
                    } else {
                        this.loading = true;
                        this.$store.dispatch('changePasswordApi', {
                            id: this.id,
                            resetKey: this.token,
                            new_pass: this.form.password
                        }).then(response => {
                            if (parseInt(response.status) === 200) {
                                this.success = true;
                            } else {
                                this.error = this.$ml.get(`passwordResetError${response.status}`)
                            }
                            this.loading = false;
                        }).catch((e) => {
                            this.loading = false;
                            self.$checkTokenExp(e);
                        });
                    }
                }
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-top: 11px;
        margin-bottom: 16px;
    }

    .margin-fix {
        margin-bottom: 19px;
    }
</style>
