<template>
    <div class="container">
        <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
        <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Authenticate"
    }
</script>

<style scoped>
    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

</style>
