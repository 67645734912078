<template>
    <div class="eco-sidebar">
        <div class="eco-sidebar-wrapper">
            <ul class="eco-sidebar-nav font-bebas-neue">
                <li v-if="isAdmin">
                    <router-link :to="{name: 'adminCards'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="chart-bar"/></span> {{$ml.get('CARD_REPORTS')}}
                    </router-link>
                </li>
                <li v-else>
                    <router-link :to="{name: 'employeeCards'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="users"/></span> {{$ml.get('EMPLOYEE_CARDS')}}
                    </router-link>
                </li>
                <li v-if="false">
                    <router-link :to="{name: 'billingEstimate'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="file-invoice-dollar"/></span> {{$ml.get('BILLING_ESTIMATE')}}
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name: 'companyCoordinates'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="map-marker-alt"/></span> {{$ml.get('COMPANY_COORDINATES')}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'cardDesign'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="palette"/></span> {{$ml.get('CARD_DESIGN')}}
                    </router-link>
                </li>
                <li v-if="false">
                    <router-link v-if="userCompany.attributes.isAgency" :to="{name: 'clients'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('CLIENTS')}}
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="goToClients" v-if="userCompany.attributes.isAgency">
            <button-field
                    class="w-100"
                    @click="goToClients()"
                    icon="user-tag"
                    color="primary">{{$ml.get('MANAGE_CLIENTS')}}
            </button-field>
        </div>
    </div>
</template>

<script>
    import Parse from 'parse';
    export default {
        name: "DashboardLayoutNav",
        data(){
          return {
              ClientPopupActive: false,
              userCompany: this.$store.state.userCompany,
              mode: process.env.VUE_APP_MODE,
              isAdmin: false,
          }
        },
        mounted() {
            let curUser = Parse.User.current();
            if(curUser && curUser.attributes){
                this.isAdmin = curUser.attributes.isAdmin;
            }
        },
        computed: {
            isInClients(){
              if(this.$route.name == 'clients'){
                  return true;
              }else {
                  return false;
              }
            },
        },
        methods: {
            goToClients() {
                this.$router.push({name: 'clients'});
            }
        }
    }
</script>

<style scoped>
    .goToClients{
        margin-top: auto;
        padding: 25px 15px;
    }
</style>
