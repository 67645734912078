import Vue from 'vue'
import underscore from 'vue-underscore'
import App from './App.vue'
import Notifications from 'vue-notification'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faUserSecret,
    faSpinner,
    faCheck,
    faUsers,
    faPalette,
    faMapMarkerAlt,
    faWallet,
    faUser,
    faUserTag,
    faTimes,
    faCogs,
    faSignOutAlt,
    faPlus,
    faBell,
    faFileImport,
    faShareSquare,
    faTrashAlt,
    faEdit,
    faDownload,
    faUpload,
    faSave,
    faKey,
    faCopy,
    faArrowLeft,
    faCheckCircle,
    faPaperPlane,
    faSignInAlt,
    faEye,
    faChartBar,
    faFileInvoiceDollar,
    faFileExport,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import GlobalLoader from './components/global/GlobalLoader'
import InputField from './components/global/InputField'
import ButtonField from './components/global/ButtonField'
import ColorField from './components/global/ColorField'
import SliderField from './components/global/SliderField'
import CheckboxField from './components/global/CheckboxField'
import SelectField from './components/global/SelectField'
import DropzoneField from './components/global/DropzoneField'

import VTooltip from 'v-tooltip'

import {clickOutside} from "./directives/clickOutside";

import './translations/ml'

const Parse = require('parse');
Parse.initialize(`${process.env.VUE_APP_PARSE_APP_ID}`, `${process.env.VUE_APP_PARSE_SECRET}`);
Parse.serverURL = `${process.env.VUE_APP_PARSE_URL}`;

Vue.config.productionTip = false;

library.add(
    faUserSecret,
    faSpinner,
    faCheck,
    faUsers,
    faPalette,
    faMapMarkerAlt,
    faWallet,
    faUser,
    faUserTag,
    faTimes,
    faCogs,
    faSignOutAlt,
    faPlus,
    faBell,
    faFileImport,
    faShareSquare,
    faTrashAlt,
    faEdit,
    faDownload,
    faUpload,
    faSave,
    faKey,
    faCopy,
    faArrowLeft,
    faCheckCircle,
    faPaperPlane,
    faSignInAlt,
    faEye,
    faChartBar,
    faFileInvoiceDollar,
    faFileExport,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('global-loader', GlobalLoader);
Vue.component('input-field', InputField);
Vue.component('button-field', ButtonField);
Vue.component('color-field', ColorField);
Vue.component('slider-field', SliderField);
Vue.component('checkbox-field', CheckboxField);
Vue.component('select-field', SelectField);
Vue.component('dropzone-field', DropzoneField);

Vue.directive('v-click-outside', clickOutside);

Vue.filter('dateFormat', function (value) {
    if (!value) {
        return '';
    }
    let validDateObject = new Date(value);
    let month = ('0' + (validDateObject.getMonth() + 1)).slice(-2);
    let day = ('0' + validDateObject.getDate()).slice(-2);
    return `${validDateObject.getFullYear()}-${month}-${day}`;
});

Vue.use(VueAxios, axios);
Vue.use(Notifications);
Vue.use(underscore);
Vue.use(VTooltip);

Vue.prototype.$notificationAnimation = {
    enter: {
        opacity: [1, 0],
        translateX: [0, -300],
        scale: [1, 0.2]
    },
    leave: {
        opacity: [0, 1],
        height: 0
    }
};

Vue.prototype.$goToLogin = function() {
    let self = this;
    this.$store.dispatch('logout').then().catch(function(){
        self.$store.commit('setIsAuthenticated', false);
        self.$store.commit('setUser', null);
        self.$router.push('/auth')
        self.$notify({
            group: 'notification',
            type: 'error',
            text: self.$ml.get('notification_login_expired')
        });
    });
};

Vue.prototype.$checkTokenExp = function(err) {
    //let self = this;
    if(err.code == 209){
        this.$goToLogin();
    }else {
        //console.log(err);
    }

};

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
