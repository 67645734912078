<template>
    <button :type="type"
            :disabled="disabled"
            class="form-button font-bebas-neue"
            :class="buttonClass"
            @click="buttonClicked">
        <div class="form-button-icon fa-xs" v-if="icon.length">
            <font-awesome-icon :icon="icon"/>
        </div>
        <slot></slot>
        <div class="form-button-loading fa-xs" v-if="loading">
            <font-awesome-icon icon="spinner" spin/>
        </div>
    </button>
</template>

<script>
    export default {
        name: "ButtonField",
        props: {
            type: {
                type: String,
                default: 'button'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            },
            block: {
                type: Boolean,
                default: false
            },
            circle: {
                type: Boolean,
                default: false
            },
            outline: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            buttonClass() {
                let result = [];
                if (this.block) {
                    result.push('button-block');
                }
                if (this.circle) {
                    result.push('button-circle');
                }
                if (this.outline) {
                    result.push('button-outline');
                }
                switch (this.color) {
                    case 'primary':
                        result.push('color-primary');
                        break;
                    case 'secondary':
                        result.push('color-secondary');
                        break;
                    case 'dark':
                        result.push('color-dark');
                        break;
                    case 'error':
                        result.push('color-error');
                        break;
                }

                return result.join(' ')
            }
        },
        methods: {
            buttonClicked() {
                this.$emit('click')
            }
        }
    }
</script>

<style scoped>
    .button-block {
        width: 100%;
    }

    .form-button {
        position: relative;
        white-space: nowrap;
        height: 24px;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 12px;
        color: var(--color-white);
        border: 1px solid var(--color-grey);
        background: var(--color-grey);
        text-align: center;
        padding: 0 16px 0 23px;
        opacity: 1;
        cursor: pointer;
        /* transition: all 0.25s ease-in-out; */
    }

    .form-button:focus,
    .form-button:hover {
        background: var(--color-dark);
        border-color: var(--color-dark);
        box-shadow: none;
        outline: none;
    }

    .form-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .form-button.button-circle {
        width: 24px;
        padding: 0;
    }

    .form-button.color-primary {
        background: var(--color-primary);
        border-color: var(--color-primary);
    }

    .form-button.color-primary:focus,
    .form-button.color-primary:hover {
        background: var(--color-primary-dark);
        border-color: var(--color-primary-dark);
    }

    .form-button.color-secondary {
        background: var(--color-secondary);
        border-color: var(--color-secondary);
    }

    .form-button.color-secondary:focus,
    .form-button.color-secondary:hover {
        background: var(--color-secondary-dark);
        border-color: var(--color-secondary-dark);
    }

    .form-button.color-dark {
        background: var(--color-dark);
        border-color: var(--color-dark);
    }

    .form-button.color-dark:focus,
    .form-button.color-dark:hover {
        background: var(--color-dark);
        border-color: var(--color-dark);
    }

    .form-button.color-error {
        background: var(--color-error);
        border-color: var(--color-error);
    }

    .form-button.color-error:focus,
    .form-button.color-error:hover {
        background: var(--color-error-dark);
        border-color: var(--color-error-dark);
    }

    .form-button.button-outline.color-primary,
    .form-button.button-outline.color-secondary,
    .form-button.button-outline.color-error,
    .form-button.button-outline {
        background: transparent;
    }

    .form-button.button-outline {
        color: var(--color-grey);
    }

    .form-button.button-outline:hover,
    .form-button.button-outline:focus {
        background: var(--color-dark);
        border-color: var(--color-dark);
        color: var(--color-white);
    }

    .form-button.button-outline.color-primary {
        color: var(--color-primary);
    }

    .form-button.button-outline.color-primary:hover,
    .form-button.button-outline.color-primary:focus {
        background: var(--color-primary-dark);
        border-color: var(--color-primary-dark);
        color: var(--color-white);
    }

    .form-button.button-outline.color-secondary {
        color: var(--color-secondary);
    }

    .form-button.button-outline.color-secondary:hover,
    .form-button.button-outline.color-secondary:focus {
        background: var(--color-secondary-dark);
        border-color: var(--color-secondary-dark);
        color: var(--color-white);
    }

    .form-button.button-outline.color-dark {
        color: var(--color-dark);
    }

    .form-button.button-outline.color-dark:hover,
    .form-button.button-outline.color-dark:focus {
        background: var(--color-dark);
        border-color: var(--color-dark);
        color: var(--color-white);
    }

    .form-button.button-outline.color-error {
        color: var(--color-error);
    }

    .form-button.button-outline.color-error:hover,
    .form-button.button-outline.color-error:focus {
        background: var(--color-error-dark);
        border-color: var(--color-error-dark);
        color: var(--color-white);
    }

    .form-button-loading,
    .form-button-icon {
        position: absolute;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-button-icon {
        top: 0;
        left: 0;
    }

    .form-button-loading {
        top: 0;
        right: 0;
    }
</style>
