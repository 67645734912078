import {UserService} from './UserService'
import {CompanyService} from './CompanyService'
import {OfficeAddressService} from './OfficeAddressService'
import {EmployeeService} from './EmployeeService'
import {ClientService} from './ClientsService'
import {InvoiceService} from './InvoiceService'
import {CardConfigService} from './CardConfigService'

export const actions = {
    ...UserService,
    ...CompanyService,
    ...OfficeAddressService,
    ...EmployeeService,
    ...ClientService,
    ...InvoiceService,
    ...CardConfigService
};
