<template>
    <div id="dashboard" class="eco-container-fluid h100">
        <div class="bg-white h100 relative" v-if="userCompany !== null">
            <dashboard-layout-header></dashboard-layout-header>
            <dashboard-layout-nav></dashboard-layout-nav>
            <dashboard-right-modal></dashboard-right-modal>
            <div class="eco-content-container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardLayoutNav from './LayoutNav'
    import DashboardLayoutHeader from './LayoutHeader'
    import DashboardRightModal from './RightModal'

    export default {
        name: "DashboardLayout",
        components: {
            DashboardLayoutNav,
            DashboardLayoutHeader,
            DashboardRightModal
        },
        computed: {
            userCompany() {
                return this.$store.state.userCompany
            }
        },
        created() {
            let self = this;
            let promises = [
                self.$store.dispatch('getCardTemplates'),
                self.$store.dispatch('getCardConfig'),
                self.$store.dispatch('getCompanyAddress'),
            ];
            self.$store.dispatch('currentUser').then(function(){
                Promise.all(promises)
                    .then()
                    .catch(function(err){
                        self.$checkTokenExp(err);
                    });
            });

        },
        methods: { }
    }
</script>

<style scoped>

</style>
