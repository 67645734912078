<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_CLIENT'):$ml.get('EDIT_CLIENT_COMPANY')}}
        </h3>
        <div class="row">
            <div class="col">
                <h4 class="mb-1 mt-3">{{$ml.get('COMPANY_INFORMATION')}}</h4>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-4">
                <dropzone-field name="logo"
                                id="logo"
                                :accept="`image/*`"
                                :required="true"
                                @validation-result="validationCollector"
                                v-model="form.logo">
                    <img :src="form.logo"
                         v-if="form.logo">
                    <template v-else>
                        <div v-html="$ml.get('customizeCardDropzone')"></div>
                    </template>
                </dropzone-field>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
            <input-field :id="`name`"
                         :name="'name'"
                         :value="form.name"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.name">{{$ml.get('COMPANY_NAME')}}
            </input-field>
            </div>
            <div class="col-6">
            <input-field :id="`vatNumber`"
                         :name="'vatNumber'"
                         :value="form.vatNumber"
                         :required="false"
                         :ext-validator="vatValidator"
                         @validation-result="validationCollector"
                         v-model="form.vatNumber">{{$ml.get('VAT_NUMBER')}}
            </input-field>
            </div>
            <div class="col-12">
                <input-field :id="`billingAddress`"
                             :name="'billingAddress'"
                             :value="form.billingAddress"
                             :required="true"
                             @validation-result="validationCollector"
                             v-model="form.billingAddress">{{$ml.get('BILLING_ADDRESS')}}
                </input-field>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <input-field :id="`responsible`"
                             :name="'responsible'"
                             :value="form.responsible"
                             :required="false"
                             @validation-result="validationCollector"
                             v-model="form.responsible">{{$ml.get('RESPONSIBLE')}}
                </input-field>
            </div>
            <div class="col-6">
            <input-field :id="`telephone`"
                         :name="`telephone`"
                         :value="form.telephone"
                         :required="false"
                         @validation-result="validationCollector"
                         v-model="form.telephone">{{$ml.get('TELEPHONE')}}
            </input-field>
            </div>

        </div>

        <div class="row">
            <div class="col-6">
                <input-field :id="`billingEmail`"
                             :name="'billingEmail'"
                             :value="form.billingEmail"
                             :required="true"
                             :ext-validator="emailValidator"
                             @validation-result="validationCollector"
                             v-model="form.billingEmail">{{$ml.get('EMAIL')}}
                </input-field>
            </div>
            <div class="col-6">
            <input-field :id="`website`"
                         :name="'website'"
                         :value="form.website"
                         :required="false"
                         @validation-result="validationCollector"
                         v-model="form.website">{{$ml.get('WEBSITE')}}
            </input-field>
            </div>
        </div>

        <div v-if="!id">
            <div class="row">
                <div class="col">
                    <h4 class="mb-1 mt-3">{{$ml.get('INITIAL_CARD_TEMPLATE')}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select-field name="cardTemplate"
                                  id="cardTemplate"
                                  :value="form.cardTemplate"
                                  v-model="form.cardTemplate"
                                  :required="true"
                                  @validation-result="validationCollector"
                                  :options="availableTemplates">
                        {{$ml.get('CARD_DESIGN_TEMPLATE')}}
                    </select-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <h4 class="mb-1 mt-3">{{$ml.get('DEFAULT_OFFICE_ADDRESS')}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <input-field :id="`office-address`"
                                 :name="'office-address'"
                                 :value="form.officeAddress.street"
                                 :required="true"
                                 @validation-result="validationCollector"
                                 v-model="form.officeAddress.street">{{$ml.get('STREET_ADDRESS')}}
                    </input-field>
                </div>
                <div class="col-6">
                    <input-field :id="`office-zip`"
                                 :name="'office-zip'"
                                 :value="form.officeAddress.zip"
                                 :ext-validator="zipCodeValidator"
                                 @validation-result="validationCollector"
                                 v-model="form.officeAddress.zip">{{$ml.get('ZIP')}}
                    </input-field>
                </div>
                <div class="col-6">
                    <input-field :id="`office-city`"
                                 :name="'office-city'"
                                 :value="form.officeAddress.city"
                                 :required="true"
                                 @validation-result="validationCollector"
                                 v-model="form.officeAddress.city">{{$ml.get('CITY')}}
                    </input-field>
                </div>
                <div class="col-6">
                    <input-field :id="`office-state`"
                                 :name="'office-state'"
                                 :value="form.officeAddress.state"
                                 v-model="form.officeAddress.state">{{$ml.get('STATE')}}
                    </input-field>
                </div>
                <div class="col-6">
                    <input-field :id="`office-country`"
                                 :name="'office-country'"
                                 :value="form.officeAddress.country"
                                 :required="true"
                                 @validation-result="validationCollector"
                                 v-model="form.officeAddress.country">{{$ml.get('COUNTRY')}}
                    </input-field>
                </div>
            </div>
        </div>
        <div>
            <!--<checkbox-field color="secondary"-->
                            <!--:value="form.customAddress"-->
                            <!--v-model="form.customAddress"-->
            <!--&gt;{{$ml.get('USE_CUSTOM_ADDRESS')}}-->
            <!--</checkbox-field>-->
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardClientsForm",
        computed: {
            id() {
                return this.$store.state.dashboardModalComponent.data.id
            },
            selectedClient() {
                return this.$store.getters.selectedModalData('employeeCards');
            },
            availableTemplates() {
                let templates = [];
                if(this.$store.state.cardTemplates){
                    this.$store.state.cardTemplates.forEach((template) => {
                        let element = {
                            value: template.id,
                            text: template.attributes.name
                        };
                        templates.push(element);
                    })
                }
                return templates;
            },
            emailValidator(){
                return function(value) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(value).toLowerCase());
                }
            },
            vatValidator() {
                return function(value) {
                    let re = /(AT?U[0-9]{8}$|BE?0[0-9]{9}$|BG?[0-9]{9,10}$|HR?[0-9]{11}$|CY?[0-9]{8}[A-Z]$|CZ?[0-9]{8,10}$|DE?[0-9]{9}$|DK?[0-9]{8}$|EE?[0-9]{9}$|EL?[0-9]{9}$|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$|FI?[0-9]{8}$|FR?[0-9A-Z]{2}[0-9]{9}$|GB?([0-9]{9}([0-9]{3}?|[A-Z]{2}[0-9]{3}))$|HU?[0-9]{8}$|IE?[0-9]{7}[A-Z]{1,2}$|IE?[0-9][A-Z][0-9]{5}[A-Z]$|IT?[0-9]{11}$|LT?([0-9]{9}|[0-9]{12})$|LU?[0-9]{8}$|LV?[0-9]{11}$|MT?[0-9]{8}$|NL?[0-9]{9}B[0-9]{2}$|PL?[0-9]{10}$|PT?[0-9]{9}$|RO?[0-9]{2,10}$|SE?[0-9]{12}$|SI?[0-9]{8}$|SK?[0-9]{10})$/;
                    let result = re.test(String(value));
                    return result;
                };
            },
            zipCodeValidator() {
              return function (value) {
                  let re = /[\w]*[\w-]*[\w ]*\d{4,}/;
                  return re.test(value);
              }
            },
            isFormDisabled(){
                return this.errors.length > 0;
            }
        },
        data() {
            let initialAttr = this.$store.getters.selectedModalData('agencyClients');
            return {
                form: {
                    name: initialAttr ? initialAttr.attributes.name : '',
                    vatNumber:  initialAttr ? initialAttr.attributes.vatNumber : '',
                    billingAddress:  initialAttr ? initialAttr.attributes.billingAddress : '',
                    responsible:  initialAttr ? initialAttr.attributes.responsible : '',
                    billingEmail: initialAttr ? initialAttr.attributes.billingEmail : '',
                    telephone: initialAttr ? initialAttr.attributes.telephone : '',
                    website: initialAttr ? initialAttr.attributes.website : '',
                    billingName: initialAttr ? initialAttr.attributes.billingName : '',
                    cardTemplate: initialAttr ? initialAttr.attributes.billingName : '',
                    logo: initialAttr ? initialAttr.attributes.logo._url: null,
                    officeAddress: {
                        street: '',
                        zip: '',
                        city: '',
                        state: '',
                        country: '',
                        compId: null
                    }
                },
                loading: false,
                errors: []
            }
        },
        mounted() {

            // if (this.selectedClient !== null) {
            //     this.form = {
            //         ...this.form,
            //         ...this.selectedClient.attributes
            //     }
            // }
        },
        methods: {
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            submitForm() {
                let self = this;
                //this.validateFields(this.form, this.errors);
                if (!self.loading) {
                    self.loading = true;

                    self.$store.dispatch('saveClient', {id: self.id, ...self.form}).then((resp) => {
                        //Use Company data to pre-fill default address
                        let newClient = resp;
                        if(!self.id){

                            //Create new default address if it is a new company

                            self.form.officeAddress.compId = newClient.id;
                            self.form.officeAddress.officeLabel = "Default";
                            self.form.officeAddress.officePhone = self.form.telephone;
                            self.form.officeAddress.website = self.form.website;
                            this.$store.dispatch('saveOfficeAddress', {id: null, ...self.form.officeAddress})
                                    .then(() => {
                                        self.$store.dispatch('createCardConfig', {...self.form}).then(function(){
                                            self.$store.dispatch('changeImpersonation', newClient).then(function() {
                                                self.$store.commit('setCardCompany', newClient);
                                                self.$store.dispatch('getAgencyClients')
                                                        .then(() => {
                                                            self.loading = false;
                                                            self.$notify({
                                                                group: 'notification',
                                                                type: 'success',
                                                                text: self.$ml.get('notification_success_saved'),
                                                            });
                                                            self.$store.commit('toggleDashboardModal');
                                                        });
                                            });
                                        });
                                    })
                                    .catch(() => {
                                        self.$notify({
                                            group: 'notification',
                                            type: 'error',
                                            title: this.$ml.get('notification_error_title'),
                                            text: this.$ml.get('notification_error_general'),
                                        });
                                        //self.$checkTokenExp(e);
                                        self.loading = false;
                                    });
                        }else{
                            self.$notify({
                                group: 'notification',
                                type: 'success',
                                text: self.$ml.get('notification_success_saved'),
                            });
                            self.$store.commit('toggleDashboardModal');
                        }

                        //Switch to newly created company for impersonation

                        // console.log("State: ",this.$store.state);
                        // console.log("Form: ", this.form);



                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
