<template>
    <div class="form-component" :class="assignClasses">
        <input class="form-field"
               type="text"
               :name="name"
               :value="value"
               @input="updateValue($event.target.value)">
        <input class="color-field"
               type="color"
               :value="value"
               @input="updateValue($event.target.value)"
               :id="id">
        <label class="form-label"
               :for="id"><slot></slot></label>
        <span class="form-error font-bebas-neue">{{error}}</span>
    </div>
</template>

<script>
    export default {
        name: "ColorField",
        props: {
            name: String,
            error: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            },
            id: String,
            value: String,
        },
        computed: {
            assignClasses() {
                let resultClasses = '';
                let regex = /^#[0-9a-f]{6}$/i;
                if (this.value.length) {
                    resultClasses += 'form-has-value ';
                }
                if (this.error.length || !this.value.match(regex)) {
                    resultClasses += 'color-error';
                } else {
                    switch (this.color) {
                        case 'primary':
                            resultClasses += 'color-primary';
                            break;
                        case 'secondary':
                            resultClasses += 'color-secondary';
                            break;
                        case 'error':
                            resultClasses += 'color-error';
                            break;
                        default:
                            break;
                    }
                }
                return resultClasses;
            },
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value)
            },
        }
    }
</script>

<style scoped>
    .form-component {
        min-height: 50px;
        position: relative;
        padding-top: 14px;

        /*border: 1px solid red;*/
    }

    .form-field {
        width: 100%;
        border: 0px solid var(--color-grey);
        border-bottom-width: 1px;
        background: transparent;
        font-size: 14px;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
    }

    .form-field:focus {
        box-shadow: none;
        outline: none;
        color: var(--color-dark);
        border-bottom-color: var(--color-dark);
    }

    .form-label {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        text-transform: uppercase;
        font-size: 12px;
        cursor: text;
        pointer-events: none;
    }

    .color-error > .form-label,
    .form-has-value > .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-field:focus ~ .form-label {
        top: 0;
        font-size: 10px;
    }

    .color-error > .form-error {
        opacity: 1;
    }

    .color-field {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 10px;
        right: 0;
    }

    .color-field:focus {
        box-shadow: none;
        outline: none;
    }

    .color-error > .form-field:focus ~ .form-label,
    .color-error > .form-label,
    .color-error > .form-field,
    .color-error > .form-error {
        color: var(--color-error);
    }

    .color-error > .form-field {
        border-bottom-color: var(--color-error);
    }

    .color-error > .form-error {
        opacity: 1;
    }

    .color-error > .form-field:focus ~ .form-error,
    .color-error > .form-field:focus ~ .form-label {
        color: var(--color-error-dark);
    }

    .color-error > .form-field:focus {
        color: var(--color-error-dark);
        border-bottom-color: var(--color-error-dark);
    }

    .color-primary > .form-field:focus ~ .form-label,
    .color-primary > .form-label,
    .color-primary > .form-field,
    .color-primary > .form-error {
        color: var(--color-primary);
    }

    .color-primary > .form-field {
        border-bottom-color: var(--color-primary);
    }

    .color-primary > .form-field:focus ~ .form-label {
        color: var(--color-primary-dark);
    }

    .color-primary > .form-field:focus {
        color: var(--color-primary-dark);
        border-bottom-color: var(--color-primary-dark);
    }

    .color-secondary > .form-field:focus ~ .form-label,
    .color-secondary > .form-label,
    .color-secondary > .form-field,
    .color-secondary > .form-error {
        color: var(--color-secondary);
    }

    .color-secondary > .form-field {
        border-bottom-color: var(--color-secondary);
    }

    .color-secondary > .form-field:focus ~ .form-label {
        color: var(--color-secondary-dark);
    }

    .color-secondary > .form-field:focus {
        color: var(--color-secondary-dark);
        border-bottom-color: var(--color-secondary-dark);
    }
</style>
