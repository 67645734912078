<template>
    <div class="eco-header">
        <div class="eco-logo"><img :src="logoModeUrl" alt="logo"></div>
        <div class="eco-header-nav">
            <div v-if="userCompany.attributes.isAgency" id="company_selector">
                <Select2
                    v-model="activeCompanyId"
                    :options="selectCompanies"
                    :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff}
                    }"
                    @select="companyPicked($event)"
                />
            </div>
            <div class="search">Search</div>
            <ul class="main-nav">
                <li style="display:none">
                    <font-awesome-icon icon="bell"/>
                    <div class="notification"></div>
                </li>
                <li v-click-outside="clickOutside">
                    <span class="hello">{{$ml.get('Hello_user')}}<br/>{{user.attributes.firstName + ' ' + user.attributes.lastName}}</span>
                    <button class="profile"
                            @click="profileDropdown">
                        <span class="profile-avatar">
                            <img :src="user.attributes.avatar._url"
                                 :alt="user.attributes.username"
                                 v-if="user.attributes.avatar !== undefined">
                            <font-awesome-icon icon="user" v-else/>
                        </span>
                    </button>
                    <!--<transition enter-active-class="animated fadeIn faster"
                                leave-active-class="animated fadeOut faster"
                                mode="out-in">-->
                        <div class="profile-dropdown"
                             v-show="ProfileDropdownActive">
                            <ul class="font-bebas-neue">
                                <li>
                                    <router-link :to="{name: 'billing'}">
                                        <font-awesome-icon icon="wallet"/>{{$ml.get('BILLING')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'profile'}">
                                    <font-awesome-icon icon="cogs"/>{{$ml.get('ACCOUNT_SETTINGS')}}
                                    </router-link>
                                </li>
                                <li>
                                    <a href="#" @click.prevent="logout">
                                    <font-awesome-icon icon="sign-out-alt"/>{{$ml.get('LOG_OUT')}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    <!--</transition>-->
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Select2 from 'v-select2-component';

    export default {
        name: "DashboardLayoutHeader",
        components: {
            Select2
        },
        computed: {
            isInClients(){
                if(this.$route.name == 'clients'){
                    return true;
                }else {
                    return false;
                }
            },
            selectCompanies(){
                let selectData = [];
                if(this.allClientCompanies){
                    this.allClientCompanies.forEach(function(oneCompany){
                        let oneLine = {
                            id: oneCompany.id,
                            text:  oneCompany.attributes.name,
                            fullData: oneCompany.attributes,
                            companyObj: oneCompany
                        }
                        selectData.push(oneLine);
                    });
                }
                return selectData;
            },
            user() {
                if(this.$store.state.user){
                    return this.$store.state.user
                }else{
                    return {
                        attributes: {
                            firstName: '',
                            lastName: ''
                        }
                    }
                }
            },
            clientCompany() {
                return this.$store.state.cardCompany;
            },
            allClientCompanies() {
                return this.$store.state.agencyClients;
            },
            logoModeUrl(){
              let logoSrc = require('../../assets/ecobcard-logo-white.svg');

              if(this.mode !== 'production'){
                  logoSrc = require('../../assets/ecobcard-logo-white-dev.svg');
              }
              return logoSrc;
            }
        },
        data() {
            return {
                activeCompanyId: '',
                ProfileDropdownActive: false,
                ClientPopupActive: false,
                userCompany: this.$store.state.userCompany,
                mode: process.env.VUE_APP_MODE
            }
        },
        mounted(){
            this.$store.dispatch('getCompanyAddress').then(() => {
                this.$store.dispatch('getAgencyClients').then(() => {
                });
            });
            this.activeCompanyId = this.$store.state.cardCompany.id;
        },
        methods: {
            goToClients(){
                this.$router.push({name: 'clients'});
            },
            selectionPreview(selection){

                let markup = '';
                if(selection.fullData){
                    let item = selection.fullData;
                    markup = `<div class="company selection">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            companyPreview(choice){
                let markup = '';
                if(choice.fullData){
                    let item = choice.fullData;
                    markup = `<div class="company">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                    markup += `</span>`;
                    markup += `<h3>`+item.name+`</h3>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            companyPicked(selectData){
                let clientComp = selectData.companyObj;
                let self = this;
                this.$store.dispatch('changeImpersonation', clientComp).then(function(){
                    self.$store.commit('setCardCompany', clientComp);
                    self.$store.dispatch('getEmployeeCardCount').then(() => {
                    });
                    //self.$store.dispatch('setEmployeeCardPage', 1);
                    self.$store.dispatch('getEmployeeCards').then(() => {
                        self.$store.dispatch('getCompAddresses');
                    });
                    self.$store.dispatch('getCardConfig');
                });
                this.ClientPopupActive = false;
            },
            profileDropdown() {
                this.ProfileDropdownActive = !this.ProfileDropdownActive;
            },
            switchClient() {
                this.ClientPopupActive = !this.ClientPopupActive;
                this.$store.commit('closeRightModal');
            },
            clickOutside() {
                if (this.ProfileDropdownActive) {
                    this.profileDropdown();
                }
            },
            changeClient(clientComp){
                let self = this;
                this.$store.dispatch('changeImpersonation', clientComp).then(function(){
                    self.$store.commit('setCardCompany', clientComp);
					self.$store.dispatch('getEmployeeCardCount').then(() => {
					});
					self.$store.dispatch('setEmployeeCardPage', 1);
                    self.$store.dispatch('getEmployeeCards').then(() => {
                        self.$store.dispatch('getCompAddresses');
                    });
                    self.$store.dispatch('getCardConfig');
                });
                this.ClientPopupActive = false;
            },
            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            }
        },
        watch: {
            isInClients(newVal){
                if(newVal === false){
                    this.activeCompanyId = this.$store.state.cardCompany.id;
                }
            }
        }
    }

</script>

<style >
.client_companies.side_picker {
    padding-left: 2px;
}

.side_picker .company {
    margin-bottom: 0;
    height: 41px;
    color: white;
    font-size: 10px;
    font-weight: 100;
}

#company_selector {
    width: 250px;
    margin: 0 10px;
}

#company_selector > div{
    width: 100%;
}
#company_selector .select2-container{
    width: 100% !important;
}

.search{display: none}
.hello {
    text-align: right;
    font-size: 12px;
    margin-right: 10px;
    padding-top: 2px;
}
.hello.right {
    text-align: left;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 0;
}
.all_client_companies {
    visibility: hidden;
    opacity: 0;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: hsla(0, 0%, 0%, 0.5);
    justify-content: center;
    align-items: baseline;
    z-index: 9999;
}
.all_client_companies.active {
    opacity: 1;
    visibility: visible;
}
.client_companies {
    max-height: calc(100% - 40px);
    margin: 20px;
    width: 700px;
    background: #fff;
    display: grid;
    height: auto;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(124px, 1fr));
    padding: 20px;
    overflow: auto;
    border-radius: 5px;
    transform:translateY(-100px);
    transition: transform .2s ease-out;
}
.all_client_companies.active .client_companies{
    transform:translateY(0px);
}
button.profile-client {
    display: flex;
    align-items: center;
}
li.company {
    display: flex;
    flex-direction:column;
    padding: 10px;
    align-items: center;
    text-align: center;
    cursor: pointer;
}
li.company .profile-avatar {
    width: 80px;
    height: 80px;
}

li.company h3 {
    font-size: 16px;
    margin: 10px 0 0 0;
}
.selected_company {
    display: flex;
    padding: 0 25px;
}
</style>
