<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col">
                            <div class="table-top text-center">
                                {{$ml.get('PROFILE_INFORMATION')}}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 font-bebas-neue">
                            {{$ml.get('FIRST_NAME')}}:
                        </div>
                        <div class="col-8">
                            {{user.attributes.firstName}}
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 font-bebas-neue">
                            {{$ml.get('LAST_NAME')}}:
                        </div>
                        <div class="col-8">
                            {{user.attributes.lastName}}
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 font-bebas-neue">
                            {{$ml.get('EMAIL')}}:
                        </div>
                        <div class="col-8">
                            {{user.attributes.email}}
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 font-bebas-neue">
                            {{$ml.get('AVATAR')}}:
                        </div>
                        <div class="col-8">
                            <img :src="user.attributes.avatar._url"
                                 :alt="user.attributes.username"
                                 v-if="user.attributes.avatar !== undefined"
                                 class="profile-settings-avatar">
                        </div>
                    </div>
                    <div class="row mb-1 justify-content-center">
                        <div class="col-8 text-center">
                            <button-field color="secondary"
                                          icon="edit"
                                          block
                                          @click="toggleModal('DashboardProfileForm')">
                                {{$ml.get('EDIT_INFORMATION')}}
                            </button-field>
                        </div>
                    </div>
                    <div class="row mb-1 justify-content-center">
                        <div class="col-8 text-center">
                            <button-field color="primary"
                                          icon="key"
                                          block
                                          @click="toggleModal('DashboardProfilePassword')">
                                {{$ml.get('CHANGE_PASSWORD')}}
                            </button-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardProfile",
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        data() {
            return {
                form: {
                    newPassword: '',
                    newPasswordConfirm: ''
                },
                hasError: false,
                loading: false
            }
        },
        methods: {
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName, data: null});
            }
        }
    }
</script>

<style scoped>
    .profile-settings-avatar {
        height: 100px;
        width: auto;
    }
    .eco-content-scroll{
        padding: 10px;
    }
    .eco-content-space {
        background: #FFFFFF;
        padding: 25px;
    }
</style>
