<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{$ml.get('EDIT_BILLING_INFORMATION')}}
        </h3>
        <div>
            <input-field :id="`name`"
                         :name="'name'"
                         :value="form.name"
                         v-model="form.name">{{$ml.get('COMPANY_NAME')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`vat-number`"
                         :name="'vat-number'"
                         :value="form.vatNumber"
                         v-model="form.vatNumber">{{$ml.get('VAT_NUMBER')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`billing-address`"
                         :name="'billing-address'"
                         :value="form.billingAddress"
                         v-model="form.billingAddress">{{$ml.get('BILLING_ADDRESS')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`responsible`"
                         :name="'responsible'"
                         :value="form.responsible"
                         v-model="form.responsible">{{$ml.get('RESPONSIBLE')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`billing-email`"
                         :name="'billing-email'"
                         :value="form.billingEmail"
                         v-model="form.billingEmail">{{$ml.get('BILLING_EMAIL')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`telephone`"
                         :name="'telephone'"
                         :value="form.telephone"
                         v-model="form.telephone">{{$ml.get('TELEPHONE')}}
            </input-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              icon="save"
                              type="submit"
                              :disabled="loading"
                              :loading="loading"
                              block>{{$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardBillingForm",
        computed: {
            company() {
                return this.$store.state.userCompany;
            },
        },
        data() {
            return {
                form: {
                    name: '',
                    vatNumber: '',
                    billingAddress: '',
                    responsible: '',
                    billingEmail: '',
                    telephone: '',
                },
                loading: false
            }
        },
        mounted() {
            this.updateCompany();
        },
        methods: {
            updateCompany(){
                this.form = {
                    name: this.company.attributes.name,
                    vatNumber: this.company.attributes.vatNumber,
                    billingAddress: this.company.attributes.billingAddress,
                    responsible: this.company.attributes.responsible,
                    billingEmail: this.company.attributes.billingEmail,
                    telephone: this.company.attributes.telephone,
                }
            },
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveCompany', this.form).then(() => {
                        this.updateCompany();
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
