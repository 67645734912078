import Parse from 'parse'
import Api from './Api'

export const EmployeeService = {
    getEmployeeCardCount(context) {
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);
        query.equalTo('company', context.state.cardCompany);
        return new Promise((resolve, reject) => {
            query.count().then(response => {
                context.commit('setEmployeeCardCount', response);
                resolve(response);
        }).catch(error => {
                reject(error);
            })
        });
    },
    getEmployeeCards(context) {
        let cardsPerPage = context.state.employeeCardsPP;
        let cardsPage = context.state.employeeCardPage;
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);
        query.equalTo('company', context.state.cardCompany);
        query.include('officeAddress');
        query.include('cardVer');
        query.descending('createdAt');
        return new Promise((resolve, reject) => {
            query.limit(cardsPerPage);
            query.skip((cardsPage-1)*cardsPerPage);
            query.find().then(response => {
                context.commit('setEmployeeCards', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getAllEmployeeCards(context){
        let employees = Parse.Object.extend('Employee');
        let query = new Parse.Query(employees);
        query.equalTo('company', context.state.cardCompany);
        query.descending('createdAt');
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                //context.commit('setEmployeeCards', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCompAddresses(context) {
        let compAddresses = Parse.Object.extend('OfficeAddress');
        let query2 = new Parse.Query(compAddresses);
        query2.equalTo('company', context.state.cardCompany);
        return new Promise((resolve, reject) => {
            query2.find().then(response => {
                context.commit('setOfficeAddresses', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },

    setCardPage(context, cardPage) {
        context.commit('setEmployeeCardPage', cardPage);
    },
    setCardsPP(context, cardsPP) {
        context.commit('setEmployeeCardsPP', cardsPP);
    },

    selectCard(context, selectedAddressId){
        let selected = context.state.officeAddresses.find(obj => {
            if(obj.id === selectedAddressId){
                return obj;
            }else{
                return false;
            }
        });
        return selected;
    },

    saveEmployeeCards(context, data) {
        
        let addrObject = { "__type": "Pointer", "className": "OfficeAddress", "objectId": data.officeAddress.id }

        if (data.id === null) {
            let employeeCard = Parse.Object.extend('Employee');
            let employeeCardRecord = new employeeCard();
            return new Promise((resolve, reject) => {
                employeeCardRecord.set('firstName', data.firstName);
                employeeCardRecord.set('lastName', data.lastName);
                employeeCardRecord.set('position', data.position);
                employeeCardRecord.set('department', data.department);
                employeeCardRecord.set('mobilePhone', data.mobilePhone);
                employeeCardRecord.set('officePhone', data.officePhone);
                employeeCardRecord.set('email', data.email);
                employeeCardRecord.set('cardToken', data.randString);
                employeeCardRecord.set('officeAddress', addrObject);
                employeeCardRecord.set('company', context.state.cardCompany);
                employeeCardRecord.save().then(response => {
                    context.dispatch('getEmployeeCards');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        } else {
            return new Promise((resolve, reject) => {
                context.getters.selectedModalData('employeeCards').set('firstName', data.firstName);
                context.getters.selectedModalData('employeeCards').set('lastName', data.lastName);
                context.getters.selectedModalData('employeeCards').set('position', data.position);
                context.getters.selectedModalData('employeeCards').set('department', data.department);
                context.getters.selectedModalData('employeeCards').set('mobilePhone', data.mobilePhone);
                context.getters.selectedModalData('employeeCards').set('officePhone', data.officePhone);
                context.getters.selectedModalData('employeeCards').set('email', data.email);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                context.getters.selectedModalData('employeeCards').set('officeAddress', addrObject);
                context.getters.selectedModalData('employeeCards').set('company', context.state.cardCompany);
                context.getters.selectedModalData('employeeCards').set('cardVer', Number(data.cardVer)+1);
                context.getters.selectedModalData('employeeCards').save().then(response => {
                    context.dispatch('getEmployeeCards');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    },
    deleteEmployeeCards(context) {
        return new Promise((resolve, reject) => {
            Parse.Object.destroyAll(context.getters.selectedModalData('employeeCards')).then(response => {
                context.dispatch('getEmployeeCards');
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },
    shareCards(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            company_id: context.state.cardCompany.id,
            employee_ids: data.ids,
            reset_link: data.resetLink
        }));
        
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    importEmployees(context, formData) {
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
};
