<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('REMOVE_ADDRESS')}}
        </h3>
        <div class="row">
            <div class="col">
                {{officeAddress.attributes.officeLabel}}
            </div>
        </div>
        <div class="row">
            <div class="col pt-2">
                {{officeAddress.attributes.officePhone}}
            </div>
        </div>
        <div class="row">
            <div class="col">
                {{officeAddress.attributes.officeAddress}}
            </div>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="error"
                              icon="trash-alt"
                              :loading="loading"
                              :disabled="loading"
                              block
                              @click="deleteAddress">{{$ml.get('DELETE')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardCompanyCoordinatesDelete",
        computed: {
            officeAddress() {
                return this.$store.getters.selectedModalData('officeAddresses');
            }
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            deleteAddress() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('deleteOfficeAddress').then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_delete'),
                        });
                        this.$store.commit('toggleDashboardModal');
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e)
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
