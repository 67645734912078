<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div v-if="availableCards.length > 0" class="cards-grid" v-bind:class="{ active: !isPreview }">
                <div v-for="(cardTemplate,index) in availableCards" class="wrapper-phone iphone template" @click="selectCard(cardTemplate.attributes.nice_name, cardTemplate)" v-bind:key="index">
                    <font-awesome-icon v-if="cardTemplate.id==localCardConfig.attributes.template.id" icon="check-circle"/>
                    <img v-if="cardTemplate.attributes.screenshot" :src="cardTemplate.attributes.screenshot._url" />
                    <h3 class="font-bebas-neue"><span>{{cardTemplate.attributes.name}}</span></h3>
                </div>
            </div>

            <div class="card-designs">
                <div class="text-left">
                    <button-field icon="arrow-left" color="primary" @click="backToList()">{{$ml.get('ALL_TEMPLATES')}}</button-field>
                </div>
                <div class="wrapper-phone iphone sc06">
                    <iframe v-if="frameVisible" class="cardPreview" name="cardPreview" :src="`${apiUrl}cards/render/?pr=1&comp=${$store.state.cardCompany.id}&t=${currentTemplate}&d=${useDefaults}`"  frameborder="0"></iframe>
                </div>
                <div class="text-center ">
                    <button-field icon="edit" color="secondary" @click="openConfigForm(useDefaults)">{{$ml.get('CUSTOMISE')}}</button-field>
                </div>

                <div class="text-center mt-2" v-if="localCardConfig && currentTemplate != localCardConfig.attributes.template.attributes.nice_name">
                    <button-field icon="edit" color="primary" @click="openConfigForm(0)">{{$ml.get('APPLY_MY_SETTINGS')}}</button-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "DashboardCardDesign",
        components: {},
        data() {
            return {
                isPreview: false,
                apiUrl: process.env.VUE_APP_BASE_API_URL,
                currentTemplate: '00_blank',
                useDefaults: 1,
                frameVisible: true,
                cardStack: [],
                localCardConfig: this.$store.state.cardConfig
            }

        },
        computed: {
            cardConfig() {
                //console.log(this.$store.state.cardConfig);
                //this.localCardConfig = this.$store.state.cardConfig;
                return this.$store.state.cardConfig;
            },
            availableCards() {
                return this.cardStack;
            },
        },
        mounted() {
            let self = this;
            let allPromises = [
                self.$store.dispatch('getCardTemplates'),
                self.$store.dispatch('getCardConfig'),
                self.$store.dispatch('getCompanyAddress')
            ];
            Promise.all(allPromises)
                    .then(function(e){
                        self.setupCards(e[0], e[2], e[1]);
                    })
                    .catch(function(err){
                        self.$checkTokenExp(err);
                    });
            this.$store.commit('closeRightModal');
        },
        watch: {
            /*
            sampleCards: {
                handler() {
                },
                deep: true
            }
            */
        },
        methods: {
            setupCards(allCards, currComp, cardConfig) {
                this.localCardConfig = cardConfig;

                let filteredCards = [];
                //let self = this;
                let cardComp = currComp;
                /*
                if(currComp.attributes.impersonating){
                    cardComp = currComp.attributes.impersonating;
                }else{
                    cardComp = currComp;
                }
                */

                if(cardComp){
                    if(cardComp.attributes.isAgency){
                        if(allCards.length > 0){
                            allCards.forEach(function(item){
                                if(!item.attributes.hidden || item.attributes.allowedFor.includes(cardComp.id)){
                                    filteredCards.push(item);
                                }
                            });
                        }
                    }else{
                        if(allCards.length > 0 && cardConfig) {
                            allCards.forEach(function (item) {
                                if (item.id == cardConfig.attributes.template.id) {
                                    filteredCards.push(item);
                                }
                            });
                        }
                    }
                }

                this.cardStack = filteredCards;
            },
            getCardTemplates() {

            },
            openConfigForm(useDef) {
                this.useDefaults  = useDef;
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardCardDesignForm',
                    data: {id: this.localCardConfig === undefined ? null : this.cardConfig.id, useDef: this.useDefaults}
                });
            },
            selectCard(templateName, templateObject){
                if(this.localCardConfig){
                    if(templateName == this.localCardConfig.attributes.template.attributes.nice_name){
                        this.useDefaults = 0;
                    }
                    this.$store.commit('setActiveTemplate', templateObject);
                    this.currentTemplate = templateName;
                    this.isPreview = !this.isPreview;
                    this.frameVisible = true;
                }

                //debugger;
            },
            backToList() {
                this.useDefaults  = 1;
                this.isPreview = !this.isPreview;
                this.$store.commit('closeRightModal');
                this.frameVisible = false;
            }
        }
    }
</script>

<style scoped>
.eco-content-scroll{padding: 10px}
.eco-content-space{background: #FFFFFF;padding: 25px;}
.wrapper-phone.template {cursor: pointer;box-sizing: content-box;padding-bottom: 25px}
.wrapper-phone.template h3 span {
    background: #2b2b30;
    padding: 4px 13px 3px;
    border-radius: 50px;
    color: #fff;
}
.wrapper-phone.template h3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
}
.cards-grid{
    width: 100%;
    display: grid;
    height: auto;
    gap: 20px;
    grid-column-gap: 10px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /*grid-template-columns: repeat(auto-fit, 220px);*/
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
.cards-grid:not(.active),
.cards-grid.active ~ .card-designs{
    display: none;
}
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('../../assets/images/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 12px;
    left: 14px;
    width: 376px;
    height: 815px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 14px;
    left: 16px;
    width: 380px;
    height: 819px;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 38px;
    color: var(--color-secondary);
    top: -3px;
    right: -3px;
    background: #fff;
    border-radius: 50%;
    border: 3px solid #fff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
</style>
