<template>
    <div class="container" style="padding: 30px 15px; width: 300px; margin: 30px auto; background: var(--color-white);">
        <div class="row">
            <div class="col">
                <input-field :id="`textInput`"
                             :type="'text'"
                             :error="inputField.textError"
                             :color="selectField.value"
                             :value="inputField.textInput"
                             v-model="inputField.textInput">Text Input</input-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <select-field :name="`selectField`"
                              :id="`selectField`"
                              :color="selectField.value"
                              :options="selectField.options"
                              :error="selectField.selectError"
                              :value="selectField.value"
                              v-model="selectField.value">Select Field</select-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <color-field :id="`colorInput`"
                             :error="colorInput.textError"
                             :color="selectField.value"
                             :value="colorInput.textInput"
                             v-model="colorInput.textInput">Color Input</color-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <slider-field :id="`sliderInput`"
                              :error="sliderInput.textError"
                              :color="selectField.value"
                              :min="sliderInput.min"
                              :max="sliderInput.max"
                              :step="sliderInput.step"
                              :value="sliderInput.textInput"
                              v-model="sliderInput.textInput">Slider Input</slider-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <checkbox-field :id="'checkboxId'"
                                :name="'checkboxField1'"
                                :color="selectField.value"
                                :error="checkboxField.checkboxError"
                                :value="checkboxField.checkboxValue"
                                v-model="checkboxField.checkboxValue">Normal checkbox
                </checkbox-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button-field :type="buttonField.type"
                              :disabled="buttonField.disabled"
                              :loading="buttonField.loading"
                              :color="selectField.value"
                              :icon="buttonField.icon">Primary Button
                </button-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <dropzone-field :color="selectField.value"
                                :name="'fileInput'"
                                :id="'fileInput'"
                                :accept="`image/*`"
                                :value="fileInput.value"
                                v-model="fileInput.value">Click or drop file here<br>(.png .svg recommended)
                </dropzone-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StyleComponents",
        data: () => {
            return {
                inputField: {
                    textInput: '',
                    textError: ''
                },
                fileInput: {
                    value: '',
                    error: ''
                },
                colorInput: {
                    textInput: '#2eca26',
                    textError: ''
                },
                sliderInput: {
                    textInput: '60',
                    textError: '',
                    min: 0,
                    max: 100,
                    step: 1
                },
                buttonField: {
                    type: 'button',
                    disabled: false,
                    loading: false,
                    icon: 'user-secret',
                    color: 'primary'
                },
                checkboxField: {
                    checkboxValue: false,
                    checkboxError: ''
                },
                selectField: {
                    selectError: '',
                    options: [
                        {
                            value: 'initState',
                            text: '',
                            disabled: true
                        },
                        {
                            value: '',
                            text: 'normal'
                        },
                        {
                            value: 'primary',
                            text: 'primary'
                        },
                        {
                            value: 'secondary',
                            text: 'secondary'
                        },
                        {
                            value: 'error',
                            text: 'error'
                        },
                    ],
                    value: 'initState'
                }
            }
        }
    }
</script>

<style scoped>
    .row {
        margin-bottom: 15px;
    }
</style>
