import Parse from 'parse'
import Api from './Api'

export const UserService = {
    login(context, data) {
        return Parse.User.logIn(data.username, data.password).catch(function () {
            this.$notify({
                group: 'notification',
                type: 'error',
                text: this.$ml.get('notification_login_error')
            });
        })
    },
    logout(context) {
        context.commit('resetState', true);
        return Parse.User.logOut();
    },
    currentUser(context) {
        context.commit('setGlobalLoader', true);
        return new Promise((resolve, reject) => {
            Parse.User.currentAsync().then(response => {
                context.commit('setIsAuthenticated', response !== null);
                context.commit('setUser', response);
                context.commit('setGlobalLoader', false);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        })
    },
    changePassword(context, password) {
        context.state.user.setPassword(password);
        return new Promise((resolve, reject) => {
            context.state.user.save().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeInformation(context, data) {
        for (const key in data.form) {
            context.state.user.set(key, data.form[key]);
        }
        if (data.avatar !== undefined) {
            let file = new Parse.File(data.avatar.name, data.avatar.file);
            context.state.user.set('avatar', file);
        }
        return new Promise((resolve, reject) => {
            context.state.user.save().then(response => {
                context.commit('setUser', null);
                context.commit('setUser', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    passwordReset(context, data) {
        let formData = new FormData();
        formData.append('action', 'passwordReset');
        //formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    changePasswordApi(context, data) {
        let formData = new FormData();
        formData.append('action', 'setNewPassword');
        //formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
};
