export const en = {
    EMPLOYEE_CARDS: 'EMPLOYEE CARDS',
    CARD_REPORTS: 'CARD REPORTS',
    BILLING_ESTIMATE: 'BILLING ESTIMATES',
    BILLING_PERIOD: 'BILLING PERIOD',
    NUMBER_OF_CARDS: 'NUMBER OF CARDS',
    PRICING_LEVEL: 'PRICING LEVEL',
    PRICE_PER_CARD: 'PRICE PER CARD',
    TOTAL: 'TOTAL',
    N_A: 'N/A',
    EXPORT_TO_CSV: 'EXPORT TO CSV',
    CARD_DESIGN: 'CARD DESIGN',
    COMPANY_COORDINATES: 'OFFICE ADDRESSES',
    CLIENTS: 'CLIENTS',
    MANAGE_CLIENTS: 'MANAGE CLIENTS',
    ADD_CLIENT: 'ADD CLIENT',
    DELETE_CLIENT: 'DELETE CLIENT COMPANY',
    ADD_NEW_CLIENT: 'ADD NEW CLIENT',
    CLIENT_COMPANY_NAMES: 'CLIENT COMPANY NAME',
    EDIT_CLIENT: 'EDIT CLIENT',
    CARD_COUNT: 'Card count',
    BILLING: 'BILLING',
    Hello_user: 'Hello',
    CURRENT_COMPANY: 'Current company',
    COMPANY_INFORMATION: 'COMPANY INFORMATION',
    INITIAL_CARD_TEMPLATE: 'INITIAL BCARD TEMPLATE',
    DEFAULT_OFFICE_ADDRESS: 'DEFAULT OFFICE ADDRESS',
    CURRENTLY_MANAGING_COMPANY: 'Currently managing',
    CARD_DESIGN_TEMPLATE: 'Card design template',
    ACCOUNT_SETTINGS: 'ACCOUNT SETTINGS',
    STREET_ADDRESS: 'STREET ADDRESS',
    LOG_OUT: 'LOG-OUT',
    INVOICES: 'INVOICES',
    INVOICE_N: 'INVOICE #',
    ISSUED_ON: 'ISSUED ON',
    FILENAME: 'FILENAME',
    AMOUNT: 'AMOUNT',
    ACTIONS: 'ACTIONS',
    Prev: 'Prev',
    Next: 'Next',
    COMPANY_BILLING_INFORMATION: 'COMPANY BILLING INFORMATION',
    COMPANY_NAME: 'COMPANY NAME',
    VAT_NUMBER: 'VAT NUMBER',
    BILLING_ADDRESS: 'BILLING ADDRESS',
    RESPONSIBLE: 'RESPONSIBLE',
    EMAIL: 'E-MAIL',
    TELEPHONE: 'TELEPHONE',
    EDIT_INFORMATION: 'EDIT INFORMATION',
    EDIT_BILLING_INFORMATION: 'EDIT BILLING INFORMATION',
    NAME: 'NAME',
    BILLING_EMAIL: 'BILLING EMAIL',
    SAVE: 'SAVE',
    ADD_ADDRESS: 'ADD ADDRESS',
    ADDRESS_LABEL: 'ADDRESS LABEL',
    OFFICE_PHONE: 'OFFICE PHONE',
    ADDRESS: 'ADDRESS',
    WEBSITE: 'WEBSITE',
    REMOVE_ADDRESS: 'REMOVE ADDRESS?',
    DELETE: 'DELETE',
    ADD_NEW_OFFICE_ADDRESS: 'ADD NEW OFFICE ADDRESS',
    EDIT_OFFICE_ADDRESS: 'EDIT OFFICE ADDRESS',
    ADD: 'ADD',
    ADD_EMPLOYEE: 'ADD EMPLOYEE',
    IMPORT_FROM_CSV: 'IMPORT FROM CSV',
    TOTAL_CARD_COUNT: 'Showing results',
    RESULTS_PAGE: 'Results page',
    FIRST_NAME: 'FIRST NAME',
    LAST_NAME: 'LAST NAME',
    POSITION: 'POSITION',
    DEPARTMENT: 'DEPARTMENT (optional)',
    MOBILE_PHONE: 'MOBILE PHONE',
    PRIMARY_PHONE: 'PRIMARY PHONE',
    SECONDARY_PHONE: 'SECONDARY PHONE',
    SHARE_SELECTED: 'SHARE SELECTED',
    SEND_EMPLOYEE_CARDS_TITLE: 'SEND CARDS TO EMPLOYEES',
    SEND_CARDS: 'SEND CARDS TO EMPLOYEE(S)',
    DELETE_SELECTED: 'DELETE SELECTED',
    RESULTS_PER_PAGE: 'RESULTS PER PAGE',
    Search: 'Search',
    DELETE_EMPLOYEE_CARD: 'DELETE EMPLOYEE CARD',
    EMPLOYEE_NAMES: 'EMPLOYEE NAMES',
    EMPLOYEE_RECIPIENTS: 'EMPLOYEES WHO WILL RECEIVE BUSINESS CARDS',
    SHARE_CARDS_CHECKBOX: 'Reset previous links for these cards',
    delete_employee_confirm_checkbox: 'YES I CONFIRM I WOULD LIKE TO DELETE THOSE CARDS FOR THOSE ACCOUNTS',
    delete_client_confirm_checkbox: 'YES I CONFIRM I WOULD LIKE TO DELETE THIS CLIENT AND ALL CARDS RELATED TO IT',
    beware_data_deletion: 'Beware that all data will be deleted PERMANENTLY!',
    ADD_NEW_EMPLOYEE_CARDS: 'ADD NEW EMPLOYEE CARDS',
    EDIT_EMPLOYEE_CARDS: 'EDIT EMPLOYEE CARDS',
    EDIT_CLIENT_COMPANY: 'EDIT CLIENT COMPANY',
    ALL_TEMPLATES: 'ALL TEMPLATES',
    USE_CUSTOM_ADDRESS: 'USE CUSTOM ADDRESS',
    OFFICE: 'OFFICE',
    CREATED_ON:'CREATED ON',
    UPDATED_ON:'UPDATED ON',
    SENT_ON:'SENT ON',
    VIEW_COUNT: 'VIEW COUNT',
    OFFICE_ADDRESS: 'OFFICE ADDRESS',
    ZIP: 'ZIP CODE',
    COUNTRY: 'COUNTRY',
    STATE: 'STATE',
    CITY: 'CITY',
    IMPORT_FROM_CSV_INPUT_LABEL: 'Select or drop file here',
    IMPORT_FROM_CSV_CHECKBOX_LABEL: 'Overwrite data for existing employees',
    UPLOAD: 'UPLOAD',
    IMPORT_FROM_CSV_DETAILS: 'For the data import process to work correctly hte information needs to be formatted correctly with specific column header labels.<br>To help you get started please directly download and fill-out the template file provided below.',
    PROFILE_INFORMATION: 'PROFILE INFORMATION',
    AVATAR: 'AVATAR',
    CHANGE_PASSWORD: 'CHANGE PASSWORD',
    DROP_AVATAR_HERE: 'DROP AVATAR HERE',
    NEW_PASSWORD: 'NEW PASSWORD',
    CONFIRM_NEW_PASSWORD: 'CONFIRM NEW PASSWORD',
    PASSWORD: 'PASSWORD',
    change_password_error: 'Passwords didn\'t match',
    LOGIN: 'LOGIN',
    SEND_EMAIL: 'SEND EMAIL',
    PASSWORD_CONFIRM: 'PASSWORD CONFIRM',
    RESET_PASSWORD: 'RESET PASSWORD',
    Success: 'Success',
    passwordForgottenError204: 'No users found with this e-mail.',
    passwordForgottenSuccess: 'You have successfully reset your password.<br>Check your email for the reset link.',
    passwordResetError204: 'The reset token is invalid.',
    passwordResetSuccess: 'Password changed successfully.<br>You can no login with the new password.',
    CUSTOMISE: 'CUSTOMISE',
    CUSTOMIZE_CARD: 'CUSTOMIZE CARD',
    COMPANY_LOGO: 'COMPANY LOGO',
    APPLY_MY_SETTINGS: 'APPLY MY SETTINGS',
    customizeCardDropzone: 'UPLOAD LOGO IMAGE<br>(.PNG OR .SVG RECOMMENDED)',
    IMAGE_BG_FULL: 'UPLOAD BACKGROUND<br>(.PNG OR .SVG @ 1440 x 2960 px)',
    IMAGE_BG_TOP: 'UPLOAD TOP BACKGROUND<br>(.PNG OR .SVG @ 1440 x 2960 px)',
    SIZE_LOGO: 'LOGO SIZE',
    COLOR_NAME: 'NAME COLOR',
    COLOR_TITLE: 'TITLE COLOR',
    SIZE_TITLE: 'TITLE SIZE',
    COLOR_BG_INFO: 'INFO BACKGROUND COLOR',
    COLOR_BUTTON: 'BUTTON COLOR',
    COLOR_BUTTON_TEXT: 'BUTTON TEXT COLOR',
    COLOR_READY: 'QR TITLE COLOR',
    IMG_BG_FULL: 'BACKGROUND IMAGE',
    IMG_BG_TOP: 'TOP BACKGROUND IMAGE',
    COLOR_BG_TOP: 'TOP BACKGROUND COLOR',
    COLOR_INFO: 'INFO TEXT COLOR',
    COLOR_BG_GRADIENT1: 'BACKGROUND GRADIENT 1',
    COLOR_BG_GRADIENT2: 'BACKGROUND GRADIENT 2',
    LABEL_PHONE1:'LABEL PHONE 1',
    LABEL_PHONE2:'LABEL PHONE 2',
    LABEL_EMAIL:'LABEL E-MAIL',
    LABEL_ADDRESS:'LABEL ADDRESS',
    Left: 'Left',
    Right: 'Right',
    Center: 'Center',
    ALIGNMENT: 'ALIGNMENT',
    Solid: 'Solid',
    Gradient: 'Gradient',
    COLORS: 'COLORS',
    PRIMARY_FONT: 'PRIMARY FONT',
    SECONDARY_FONT: 'SECONDARY FONT',
    BACKGROUND_TYPE: 'BACKGROUND TYPE',
    PRIMARY_COLOR: 'PRIMARY COLOR',
    SECONDARY_COLOR: 'SECONDARY COLOR',
    FIELD_LABELS: 'FIELD LABELS',
    notification_error_title: 'ERROR',
    notification_error_general: 'An error has occurred.',
    notification_share_card: 'Card invitations sent.',
    notification_success_saved: 'Save successful.',
    notification_success_delete: 'Delete successful.',
    notification_success_import: 'Import successful.',
    notification_login_success: 'Welcome to EcoBCard',
    notification_login_error: 'Invalid login credentials. Please try again.',
    notification_login_expired: 'Your login sessions has expired'
    
};
