<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{$ml.get('CHANGE_PASSWORD')}}
        </h3>
        <div>
            <input-field name="old-password"
                         id="old-password"
                         type="password"
                         :value="form.newPassword"
                         v-model="form.newPassword">{{$ml.get('NEW_PASSWORD')}}
            </input-field>
        </div>
        <div>
            <input-field name="confirm-password"
                         id="confirm-password"
                         type="password"
                         :error="hasError?$ml.get('change_password_error'):''"
                         :value="form.newPasswordConfirm"
                         v-model="form.newPasswordConfirm">{{$ml.get('CONFIRM_NEW_PASSWORD')}}
            </input-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col text-center">
                <button-field color="secondary"
                              icon="save"
                              type="submit"
                              :disabled="loading"
                              :loading="loading">
                    {{$ml.get('CHANGE_PASSWORD')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardProfilePassword",
        data() {
            return {
                form: {
                    newPassword: '',
                    newPasswordConfirm: ''
                },
                hasError: false,
                loading: false
            }
        },
        methods: {
            submitForm() {
                let self = this;
                this.hasError = this.form.newPassword !== this.form.newPasswordConfirm;
                if (!this.hasError && !this.loading && this.form.newPassword.length) {
                    this.loading = true;
                    this.$store.dispatch('changePassword', this.form.newPassword).then(() => {
                        this.form = {
                            newPassword: '',
                            newPasswordConfirm: ''
                        };
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                        this.$store.commit('toggleDashboardModal')
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
