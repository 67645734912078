import Parse from 'parse'

export const CardConfigService = {
    getCardConfig(context) {
        let cardConfig = Parse.Object.extend('CardConfig');
        let query = new Parse.Query(cardConfig);
        query.equalTo('company', context.state.cardCompany);
        query.include('designVer');
        return new Promise((resolve, reject) => {
            query.first().then(response => {
                context.commit('setCardConfig', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCardTemplates(context) {
        let cardConfig = Parse.Object.extend('CardTemplate');
        let query = new Parse.Query(cardConfig);
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setCardTemplates', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    saveCardConfig(context, data) {
        let cardConfigRecord = {};
        if(data.id === null){
            let cardConfig = Parse.Object.extend('CardConfig');
            cardConfigRecord = new cardConfig();
        }else{
            cardConfigRecord = context.state.cardConfig
        }

        for (const key in data.form) {
            let infoToSave = data.form[key];
            if(key === 'sizeLogo' || key === 'sizeTitle') {
                infoToSave = parseInt(data.form[key]);
            }
            cardConfigRecord.set(key, infoToSave);
        }

        for (const k in data.imgFiles){
            if(data.imgFiles[k] !== null){
                let file = new Parse.File(data.imgFiles[k].name, data.imgFiles[k].file);
                cardConfigRecord.set(data.imgFiles[k].field, file);
            }
        }

        cardConfigRecord.set('company', context.state.cardCompany);
        let pointer = {"__type": "Pointer", "className": "CardTemplate", "objectId": data.templateId}
        cardConfigRecord.set('template', pointer);
        cardConfigRecord.set('designVer', Number(data.designVer)+1);

        return new Promise((resolve, reject) => {
            cardConfigRecord.save().then(response => {
                context.dispatch('getCardConfig');
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        })

    },
};
