import Parse from 'parse'
import Api from './Api'
import {_} from 'vue-underscore';

export const ClientService = {

    getAgencyClients(context) {
        let companies = Parse.Object.extend('Company');
        let query = new Parse.Query(companies);

        let employees = Parse.Object.extend('Employee');
        let relatedEmployees = new Parse.Query(employees);
        let curUser = Parse.User.current();
        if(!curUser.attributes.isAdmin){
            query.equalTo('clientOf', context.state.userCompany);
        }
        query.ascending('name');

        relatedEmployees.matchesQuery('company', query);

        //query.include('officeAddress');
        return new Promise((resolve, reject) => {
            relatedEmployees.find().then(function(employeeResults){
                let groupedResults = _.groupBy(employeeResults, function(employee) {
                    return employee.get('company').id;
                });
                query.find().then(response => {
                    response.forEach(function(element){
                        if(groupedResults[element.id]){
                            element.employeeCount = groupedResults[element.id].length;
                        }else{
                            element.employeeCount = 0;
                        }

                    });
                    context.commit('setAgencyClients', response);
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })

            });


        });
    },

    changeImpersonation(context, data) {
        let pointer = {"__type": "Pointer", "className": "Company", "objectId": data.id}
        context.state.userCompany.set('impersonating', pointer);
        return new Promise((resolve, reject) => {
            context.state.userCompany.save().then(response => {
                context.dispatch('getCardConfig');
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });

    },
        /*
            getClientCardCount(context) {
                let employees = Parse.Object.extend('Employee');
                let query = new Parse.Query(employees);
                query.equalTo('company', context.state.userCompany);
                query.include('officeAddress');
                return new Promise((resolve, reject) => {
                    query.find().then(response => {
                        context.commit('setEmployeeCards', response);
                        resolve(response)
                    }).catch(error => {
                        reject(error);
                    })
                });
            },
        */

    selectCard(context, selectedAddressId){
        let selected = context.state.officeAddresses.find(obj => {
            if(obj.id === selectedAddressId){
                return obj;
            }else{
                return false;
            }
        });
        return selected;
    },

    saveClient(context, data) {
        //debugger;
        //let parentAgency = { "__type": "Pointer", "className": "Company", "objectId": context.state.userCompany }

        if (data.id === null) {
            let newClient = Parse.Object.extend('Company');
            let clientRecord = new newClient();


            return new Promise((resolve, reject) => {
                clientRecord.set('name', data.name);
                clientRecord.set('vatNumber', data.vatNumber);
                clientRecord.set('billingAddress', data.billingAddress);
                clientRecord.set('responsible', data.responsible);
                clientRecord.set('telephone', data.telephone);
                clientRecord.set('billingEmail', data.billingEmail);
                //employeeCardRecord.set('address', data.address);
                clientRecord.set('website', data.website);
                clientRecord.set('isAgency', false);
                clientRecord.set('clientOf', context.state.userCompany);
                if (data.logo !== undefined) {
                    let file = new Parse.File(data.logo.name, data.logo.file);
                    clientRecord.set('logo', file);
                }
                clientRecord.save().then(response => {
                    //context.dispatch('getAgencyClients');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        } else {
            return new Promise((resolve, reject) => {
                context.getters.selectedModalData('agencyClients').set('name', data.name);
                context.getters.selectedModalData('agencyClients').set('vatNumber', data.vatNumber);
                context.getters.selectedModalData('agencyClients').set('billingAddress', data.billingAddress);
                context.getters.selectedModalData('agencyClients').set('responsible', data.responsible);
                context.getters.selectedModalData('agencyClients').set('telephone', data.telephone);
                context.getters.selectedModalData('agencyClients').set('billingEmail', data.billingEmail);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                context.getters.selectedModalData('agencyClients').set('telephone', data.telephone);
                context.getters.selectedModalData('agencyClients').set('clientOf', context.state.userCompany);
                if (data.logo !== undefined && data.logo.file) {
                    let file = new Parse.File(data.logo.name, data.logo.file);
                    context.getters.selectedModalData('agencyClients').set('logo', file);
                }
                context.getters.selectedModalData('agencyClients').save().then(response => {
                    context.dispatch('getAgencyClients');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    },
    createCardConfig(context, data){
        let template = Parse.Object.extend('CardTemplate');
        let cardConfig = Parse.Object.extend('CardConfig');
        let newConfig = new cardConfig;
        let query = new Parse.Query(template);
        query.equalTo('objectId', data.cardTemplate);
        query.first().then(response => {

            let defaults = response.attributes.availableParams;

            defaults.forEach(function(setting){
                if(setting.fieldType !== "DropzoneField"){
                    let fieldValue = setting.defaultValue;
                    if(setting.fieldType === "SliderField"){
                        fieldValue = parseInt(setting.defaultValue);
                    }
                    newConfig.set(setting.field, fieldValue);
                }
            });
            let compPointer = {"__type": "Pointer", "className": "Company", "objectId": data.officeAddress.compId ? data.officeAddress.compId : context.state.cardCompany.id}
            let templatePointer = {"__type": "Pointer", "className": "CardTemplate", "objectId": data.cardTemplate}

            newConfig.set('company', compPointer);
            newConfig.set('template', templatePointer);
            return new Promise((resolve, reject) => {
                newConfig.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        });

    },
    deleteClient(context) {
        return new Promise((resolve, reject) => {

            let companiesToDelete = context.getters.selectedModalData('agencyClients');
            let compIds = [];

            companiesToDelete.forEach(function(element){
                compIds.push(element.id);
            });

            let cards = Parse.Object.extend('Employee');
            let cardConfig = Parse.Object.extend('CardConfig');
            let addresses = Parse.Object.extend('OfficeAddress');

            let queryCards = new Parse.Query(cards);
            let queryConfig = new Parse.Query(cardConfig);
            let queryAddress = new Parse.Query(addresses);

            queryCards.containedIn('company', compIds);
            queryConfig.containedIn('company', compIds);
            queryAddress.containedIn('company', compIds);

            let promiseCards = queryCards.find();
            let promiseConfig = queryConfig.find();
            let promiseAddress = queryAddress.find();

            let cardsToDelete = [],
                configToDelete = [],
                addressesToDelete = [];

            Promise.all([promiseCards, promiseConfig, promiseAddress]).then(function(values) {
                cardsToDelete = values[0];
                configToDelete = values[1];
                addressesToDelete = values[2];

                let delPromiseCards = Parse.Object.destroyAll(cardsToDelete);
                let delPromiseConfig = Parse.Object.destroyAll(configToDelete);
                let delPromiseAddress = Parse.Object.destroyAll(addressesToDelete);
                let delPromiseCompanies = Parse.Object.destroyAll(companiesToDelete);

                Promise.all([delPromiseCards,delPromiseConfig, delPromiseAddress, delPromiseCompanies]).then(response => {
                    //console.log("Successfully deleted objects: ", response);
                    resolve(response);
                }).catch(err => {
                    //console.log("Unable to delete objects: ", err);
                    reject(err);
                });

            });

        });
    },
    goToClientCards(context, data) {
        let formData = new FormData();
        formData.append('action', 'sendCardLink');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify({
            sender_id: context.state.user.id,
            employee_ids: data
        }));
        
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
